import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import SettingContent from "../../Masters/SettingContent/SettingContent";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import { IDS } from "../../../utils/common";
import { Rating } from "@mui/material";

library.add(fas);

const Tables = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [searchRating, setSearchRatings] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [rating_id, setRatingId] = useState(isNaN(id) ? "" : id);

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    htmlToReactParser,
    isAllow,
    IMG_URL
  } = useContext(Context);

  console.log("searchRating", searchRating);
  console.log("perPage", perPage);
  console.log("search", search);
  console.log("currentPage", currentPage);

  const getDataAll = async () => {
    const response = await getData(
      `/product/ratings?page=${currentPage || 1}&per_page=${perPage || 5}&term=${search || ""}&searchRating=${searchRating}`
      + `&rating_id=${isNaN(rating_id) ? "" : rating_id || ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1, searchRating, rating_id]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/product/ratings/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/product/ratings/${recordToDeleteId}`);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });


  return (
    <>
      <div className="main-advancedashboard">
        <Header />
        <section className="AdvanceDashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio mt-3">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="d-flex">


                        <div className="add me-3">
                          <div className="dropdown">
                            <button
                              className="btn btn-columns dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={colunms}
                                className="columns me-2 "
                                alt=""
                              />
                              Column Selection
                              <img src={top} className="top ms-1" alt="" />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col1")}
                                  href="#"
                                >
                                  Sr. No.
                                  {visible?.col1 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col2")}
                                  href="#"
                                >
                                  Customer Name
                                  {visible?.col2 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col3")}
                                  href="#"
                                >
                                  Products
                                  {visible?.col3 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col4")}
                                  href="#"
                                >
                                  Rating
                                  {visible?.col4 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col5")}
                                  href="#"
                                >
                                  Review
                                  {visible?.col5 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col6")}
                                  href="#"
                                >
                                  Image
                                  {visible?.col6 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col7")}
                                  href="#"
                                >
                                  status
                                  {visible?.col7 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col8")}
                                  href="#"
                                >
                                  Action
                                  {visible?.col8 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                      <div className="border-line mt-3"></div>
                      <div className="row mt-4">
                        <div className="  col-lg-4 col-md-12 col-12">
                          <div className="d-flex">
                            <div className="show me-2">
                              <p className="show mt-1">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries">
                              <p className="show mt-1">entries</p>
                            </div>
                          </div>
                        </div>
                        <div className="  col-lg-8  col-md-12 col-12">
                          <div className="d-flex justify-content-end">
                            <div className="sowing me-2">
                              <p className="show mt-2">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>

                            {!rating_id ? (
                              <>
                                <div className="num me-2">
                                  <select
                                    placeholder="Ratings"
                                    className="form-control"
                                    value={searchRating}
                                    onChange={(e) => {
                                      setSearchRatings(e.target.value);
                                    }}
                                  >
                                    <option value="">Search Rating</option> {/* Optional: Default option */}
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>

                                </div>
                                {/* <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div> */}
                                {/* <div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}

                                <div className="Search-1">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setSearch("");
                                      setSearchRatings("")
                                      setReset(!reset);
                                    }}
                                    className="btn btn-reset"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && <th className="sr" style={{ width: "10px" }}>Sr. No.</th>}
                              {visible.col2 && <th className="tax-name" style={{ width: "100px" }}>Customer Name</th>}
                              {visible.col3 && <th className="tax-name" style={{ width: "100px" }}>Products</th>}
                              {visible.col4 && <th className="active" style={{ width: "100px" }}>Rating</th>}
                              {visible.col5 && <th className="active" style={{ width: "300px" }}>Review</th>}
                              {visible.col6 && <th className="active" style={{ width: "100px" }}>Image</th>}
                              {visible.col7 && <th className="active" style={{ width: "100px" }}>status</th>}
                              {visible.col8 && <th className="active" style={{ width: "5px" }}>Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {" "}
                                  {/* {visible.col0 && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value={d?.id}
                                      name="perselected"
                                      checked={selectAllChecked.includes(d?.id)}
                                      onChange={handleChange}
                                      id="flexCheckDefault"
                                    ></input>
                                  </td>
                                )} */}
                                  {visible.col1 && <td>{++index}</td>}
                                  {visible.col2 && <td>{d?.user?.first_name} {d?.user?.last_name}</td>}
                                  {visible.col3 && <td>
                                    {d?.order?.orders_details?.map((detail) =>
                                      <p>{detail?.product?.name}</p>

                                    )}
                                  </td>}
                                  {/* {visible.col4 && <td>{d?.ratings}/5</td>} */}
                                  {visible.col4 && <td><Rating value={d?.ratings} readOnly /></td>}
                                  {visible.col5 && <td>{d?.description}</td>}
                                  {visible.col6 && <td>
                                    <img src={IMG_URL + d?.image} style={{ width: "100px" }} />
                                  </td>}
                                  {visible.col7 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          disabled={!isAllow.includes(IDS.ProductRatings.Edit)}
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  {visible.col8 && (
                                    <td>
                                      <div className="d-flex">
                                        <Button
                                          onClick={() => handleShow1(d?.id)}
                                          type="button"
                                          className="btn btn-primary me-1"
                                        >
                                          <FontAwesomeIcon icon="fa-solid fa-eye" />
                                        </Button>
                                        {isAllow.includes(IDS.ProductRatings.Delete) ? (
                                          <DeletButton

                                            showDeleteRecord={showDeleteRecord}
                                            id={d?.id}
                                            name={d?.name}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        {!isNaN(rating_id) && rating_id !== "" ? (
                          <div className="justify-content-center">
                            <Button
                              onClick={() => {
                                setRatingId("");
                                navigate(`/product/ratings/:id`);
                              }}
                              className="btn btn-primary me-1"
                            >
                              View All
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div >
        </section >
      </div >

      {
        show ? (
          <AddOffCanvance
            handleClose={handleClose}
            setShow={setShowAdd}
            show={show}
          />
        ) : (
          ""
        )
      }

      {
        show1 ? (
          <EditOffCanvance
            handleClose={handleClose1}
            setShow={setShowEdit}
            show={show1}
          />
        ) : (
          ""
        )
      }

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
