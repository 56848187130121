import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import SettingContent from "../../Masters/SettingContent/SettingContent";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import { IDS, Select2Data } from "../../../utils/common";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { colors } from "@mui/material";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    htmlToReactParser,
    isAllow,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [product_id, setSetProductId] = useState();
  const [category_id, setSetCategoryId] = useState();

  const formatDateInIST = (date) => {
    if (!date) return null;
    return moment(date).tz('Asia/Kolkata').format('YYYY-MM-DD'); // 'YYYY-MM-DD' format
  };

  const getDataAll = async () => {
    const response = await getData(
      `/salesreport?page=${currentPage || 1}&per_page=${perPage || 5}&term=${search || ""}&product_id=${product_id?.value || ""}` +
      `&startDate=${formatDateInIST(startDate) || ""}&endDate=${formatDateInIST(endDate) || ""}&category_id=${category_id?.value || ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  useEffect(() => {
    getDataAll();
  }, [perPage, reset, show, show1]);

  const getAllCategory = async () => {
    const response = await getData("/allcategories");
    setCategories(await Select2Data(response?.data, "category_id"));
  }

  const getAllProducts = async (id) => {
    const response = await getData(`/all-products/${id}`);
    // setProducts(Select2Data(response?.data, "product_id"));
    setProducts(await Select2Data(response?.data, "product_id"));
  }

  useEffect(() => {
    getAllCategory();
  }, [])

  console.log("startDate", startDate);



  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };


  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const getMinEndDate = () => {
    if (!startDate) return null; // If startDate is not set, return null
    const date = new Date(startDate); // Convert startDate to a Date object
    date.setDate(date.getDate() + 1); // Add one day
    return date; // Return the updated date object
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header />
        <section className="AdvanceDashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio mt-3">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="d-flex">
                        {isAllow.includes(IDS.Sale.Add) ? (
                          <div className="add me-3">
                            {data?.data?.data?.length >= 1 ? (
                              <div>{/* Your content here */}</div>
                            ) : (
                              <Link
                                // to="/product/salesreport/add"
                                type="button"
                                className="btn btn-add pe-3"
                              >
                                <div onClick={() => handleShow()}>
                                  <img
                                    src={plus}
                                    className="plus me-2 ms-0"
                                    alt=""
                                  />
                                  Add Sale
                                </div>
                              </Link>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="add me-3">
                          <div className="dropdown">
                            <button
                              className="btn btn-columns dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={colunms}
                                className="columns me-2 "
                                alt=""
                              />
                              Column Selection
                              <img src={top} className="top ms-1" alt="" />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col1")}
                                  href="#"
                                >
                                  Sr. No.
                                  {visible?.col1 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col2")}
                                  href="#"
                                >
                                  Name
                                  {visible?.col2 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col3")}
                                  href="#"
                                >
                                  Sold Quanitity
                                  {visible?.col3 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>


                            </ul>
                          </div>
                        </div>

                      </div>
                      <div className="border-line mt-3"></div>
                      <div className="row mt-4">
                        <div className="  col-lg-4 col-md-12 col-12">
                          <div className="d-flex">
                            <div className="show me-2">
                              <p className="show mt-1">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries">
                              <p className="show mt-1">entries</p>
                            </div>

                          </div>
                          <div className="sowing me-2">
                            <p className="show mt-2">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>

                        </div>

                        <div className="  col-lg-8  col-md-12 col-12">
                          <div className="d-flex justify-content-end">
                            <div className="num me-2" >
                              <Select
                                placeholder="Select Category"
                                value={category_id}
                                onChange={(selectedOption) => {
                                  setSetCategoryId(selectedOption);
                                  getAllProducts(selectedOption?.value);
                                }}
                                options={categories}
                              />
                            </div>

                            <div className="num me-2">
                              <Select
                                placeholder="Select Product"
                                value={product_id}
                                onChange={(selectedOption) => {
                                  setSetProductId(selectedOption);
                                }}
                                options={products}
                              />
                            </div>

                            <div className="num me-2">
                              <DatePicker
                                selected={startDate} // Use `selected` to bind the date picker
                                onChange={(date) => setStartDate(date)} // Set the startDate when selected
                                maxDate={endDate ? endDate : new Date()} // Prevent selecting start date after end date
                                className="form-control"
                                placeholderText="Select Start Date"
                              />
                            </div>
                            <div className="num me-2">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)} // Set the endDate when selected
                                minDate={getMinEndDate()} // Set minimum date for endDate (1 day after startDate)
                                maxDate={new Date()} // Set the maximum date to today
                                className="form-control"
                                placeholderText="Select End Date"
                              />
                            </div>

                            <div className="Search me-2">
                              <Button
                                type="button"
                                onClick={getDataAll}
                                className="btn btn-search"
                              >
                                <img src={search1} className="search" alt="" />
                              </Button>
                            </div>

                            <div className="Search-1">
                              <button
                                type="button"
                                onClick={() => {
                                  setEndDate("")
                                  setStartDate("")
                                  setSearch("");
                                  setSetProductId("");
                                  setSetCategoryId("");
                                  setReset(!reset);
                                }}
                                className="btn btn-reset"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="  col-lg-4  col-md-6 col-6"><Button
                          style={{ backgroundColor: "white", color: "blue" }}
                          onClick={() => setShowEdit(true)}
                        >
                          Download Excel
                        </Button>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && <th className="sr">Sr. No.</th>}
                              {visible.col2 && <th className="tax-name">Name</th>}
                              {visible.col3 && (
                                <th className="tax-name">Sold Quanitity</th>
                              )}
                              {/* {visible.col4 && <th className="active">Action</th>} */}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}</td>}
                                  {visible.col2 && <td>{d?.name}</td>}
                                  {visible.col3 && (
                                    <td> {d?.total_quantity}</td>
                                  )}
                                  {/* {visible.col4 && (
                                    <td>
                                      <div className="d-flex">
                                        {isAllow.includes(IDS.Country.Edit) ? (
                                          <EditButton
                                            handleShow1={() => handleShow1(d?.id)}

                                          />
                                        ) : (
                                          <></>
                                        )}

                                      </div>
                                    </td>
                                  )} */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div >
        </section >
      </div >

      {
        show ? (
          <AddOffCanvance
            handleClose={handleClose}
            setShow={setShowAdd}
            show={show}
          />
        ) : (
          ""
        )
      }

      {
        show1 ? (
          <EditOffCanvance
            handleClose={handleClose1}
            setShow={setShowEdit}
            show={show1}
          />
        ) : (
          ""
        )
      }

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
