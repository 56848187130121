// import React, { useContext } from "react";
// import { useState, useEffect } from "react";
// import "../../Tabels/Tabels.css";
// import plus from "../../../Components/assets/icons/a1.png";
// import colunms from "../../../Components/assets/icons/LINES.png";
// import pen from "../../../Components/assets/icons/pen.png";
// import basket from "../../../Components/assets/icons/basket.png";
// import search1 from "../../../Components/assets/icons/search.png";
// import top from "../../../Components/assets/icons/top.png";
// import Table from "react-bootstrap/Table";
// import circle from "../../assets/icons/circle.png";
// import rigth from "../../assets/icons/rigth.png";
// import save from "../../assets/icons/save.png";
// import cancel from "../../assets/icons/cross.png";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import Header from "../../Header/Header";

// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import Offcanvas from "react-bootstrap/Offcanvas";

// import toast, { Toaster } from "react-hot-toast";
// import Card from "react-bootstrap/Card";
// import Select from "react-select";
// import AddOffCanvance from "./Add";
// import EditOffCanvance from "./Edit";
// import {
//   Container,
//   Row,
//   Col,
//   Button,
//   Form,
//   InputGroup,
//   Nav,
// } from "react-bootstrap";
// import { Context } from "../../../utils/context";
// import { post } from "jquery";
// import { getDownloadDataExcel, postData } from "../../../utils/api";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import Successfull_Modal from "../../common/Successfull_Modal/Successfull_Modal";
// library.add(fas);

// // ********************************************************************************************************************************************************

// const Tables = () => {
//   const {
//     getData,
//     editStatusData,
//     deleteData,
//     ErrorNotify,
//     isAllow,
//     Per_Page_Dropdown,
//     Select2Data,
//     usertype,
//   } = useContext(Context);

//   const [data, setData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [perPage, setperPage] = useState(5);
//   const [search, setSearch] = useState("");
//   const [reset, setReset] = useState();
//   const [show, setShowAdd] = useState(false);
//   const [show1, setShowEdit] = useState(0);
//   const [option, setOption] = useState();

//   const [changeStatus, setChangeStatus] = useState();

//   const [selectAllChecked, setSelectAllChecked] = useState([]);
//   const [allChecked, setAllChecked] = useState([]);

//   const [customerName, setCustomerName] = useState("");
//   const [searchInvoice, setsearchInvoice] = useState("");
//   const [searchProduct, setSearchProduct] = useState("");
//   const [searchOrderStatus, setSearchOrderSatatus] = useState({});
//   const [searchPaymentStatus, setSearchPaymentSatatus] = useState("");

//   //
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const getDataAll = async () => {
//     const response = await getData(
//       `/order?page=${currentPage}&per_page=${perPage}&term=${search}&customerName=${customerName}&searchProduct=${searchProduct?.value || ""
//       }&searchOrderStatus=${searchOrderStatus?.value || ""
//       }&searchPaymentStatus=${searchPaymentStatus?.value || ""
//       }&searchInvoice=${searchInvoice}&from=${startDate}&to=${endDate}`
//     );
//     await setData(response);
//     setCurrentPage(response?.data?.currentPage);
//     setperPage(response?.data?.per_page);
//     setSearch(response?.data?.search_name);
//     setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
//     // await GetAllOrdderStatus();
//   };
//   useEffect(() => {
//     getDataAll();
//   }, [
//     changeStatus,
//     perPage,
//     reset,
//     show,
//     show1,
//     customerName,
//     searchProduct,
//     searchOrderStatus,
//     searchPaymentStatus,
//     searchInvoice,
//     startDate,
//     endDate,
//   ]);

//   console.log("searchOrderStatus", searchOrderStatus);

//   useEffect(() => { }, [searchPaymentStatus]);

//   const ChangeStatus = async (id) => {
//     const response = await editStatusData(`/order/${id}`);
//     setChangeStatus(response);
//   };

//   // Delete module.....................................................
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [recordToDeleteId, setRecordToDeleteId] = useState(null);
//   const [recordToDeleteName, setRecordToDeleteName] = useState(null);

//   const [searchStatus, setSearchStatus] = useState({});

//   const showDeleteRecord = async (id, name) => {
//     setShowDeleteModal(true);
//     setRecordToDeleteId(id);
//     setRecordToDeleteName(name);
//   };

//   const handleSelectAll = async () => {
//     await setSelectAllChecked(allChecked);
//   };

//   const handleChange = async (e) => {
//     const { value, checked } = e.target;

//     if (value === "selectAll") {
//       if (checked) {
//         handleSelectAll();
//       } else {
//         await setSelectAllChecked([]);
//       }
//     } else {
//       if (checked) {
//         await setSelectAllChecked([...selectAllChecked, Number(value)]);
//       } else {
//         await setSelectAllChecked(
//           selectAllChecked.filter((e) => e !== Number(value))
//         );
//       }
//     }
//   };

//   const [showSuccessModel, setShowSuccessModel] = useState(false);

//   // Modal function
//   const handleClose = () => setShowAdd(false);
//   const handleShow = () => setShowAdd(true);

//   const handleClose1 = () => setShowEdit(0);
//   const handleShow1 = (id) => {
//     setShowEdit(id);
//   };

//   const DeleteRecord = async () => {
//     setShowDeleteModal(false);
//     if (recordToDeleteId) {
//       const response = await deleteData(`/order/${recordToDeleteId}`);
//       await ErrorNotify(recordToDeleteName);
//       setChangeStatus(response);
//       setRecordToDeleteId(null);
//     }
//   };

//   const handleDeleteCancel = () => {
//     setShowDeleteModal(false);
//     setRecordToDeleteId(null);
//   };

//   //toggle/
//   const [visible, setVisibel] = useState({
//     col0: true,
//     col1: true,
//     col2: true,
//     col3: true,
//     col4: true,
//     col5: true,
//     col6: true,
//     col7: true,
//     col8: true,
//     col9: true,
//     col10: true,
//     col11: true,
//     col12: true,
//     col13: true,
//     col14: true,
//     col15: true,
//     col16: true,
//     customer_type: true,
//     repeat: true,
//     nxt_after_days: true,
//   });

//   //toggle columnns.........
//   const toggleColumn = (event, columnName) => {
//     event.preventDefault();
//     setVisibel((prev) => ({
//       ...prev,
//       [columnName]: !prev[columnName],
//     }));
//   };
//   const [products, setProducts] = useState({});
//   const [orderStatus, setOrderStatus] = useState([]);
//   const [orderStatuses, setOrderStatuses] = useState([]);

//   const GetAllProducts = async () => {
//     const response = await getData("/all-products");

//     if (response?.success) {
//       setProducts(await Select2Data(response?.data, "product_id"));
//     }
//   };

//   const Select3Data = async (data, name, other = false) => {
//     const result = data.map((data) => ({
//       value: data?.id,
//       label: data?.name,
//       name: name,
//       count: data?.count,
//     }));

//     if (other) {
//       result.push({ value: "0", label: "Other", name: name });
//     }
//     return result;
//   };

//   const GetAllOrdderStatus = async () => {
//     const response = await getData("/all-order-status-count");
//     if (response?.success) {
//       setOrderStatus(await Select3Data(response?.data, "order_status_id"));
//       if (!searchOrderStatus || Object.keys(searchOrderStatus).length === 0) {
//         setSearchOrderSatatus({
//           value: response?.data[0].id,
//           name: "order_status_id",
//           label: response?.data[0].name,
//         });
//       }
//     }
//   };

//   const GetAllOrdderStatuses = async () => {
//     const response = await getData("/all-order-status");

//     if (response?.success) {
//       setOrderStatuses(await Select2Data(response?.data, "order_status_id"));
//     }
//   };

//   useEffect(() => {
//     GetAllProducts();
//     GetAllOrdderStatus();
//     GetAllOrdderStatuses();
//   }, []);

//   useEffect(() => {
//     // console.log("selectAllChecked", selectAllChecked);
//   }, [selectAllChecked]);

//   const handleChangeStatus = async (id) => {
//     try {
//       if (selectAllChecked.length === 0) {
//         alert("Please select at least one row");
//         return; // Exit the function early if no rows are selected
//       }

//       const response = await postData("/order/updateStatus", {
//         selectedIds: selectAllChecked,
//         newStatusId: id,
//       });

//       if (response?.success) {
//         await getDataAll();
//         setShowSuccessModel(true);
//         GetAllOrdderStatus();
//         await setSelectAllChecked([]);
//         setTimeout(() => {
//           setShowSuccessModel(false);
//         }, 3000);

//       }
//     } catch (error) {
//       console.error("Error updating order status:", error);
//       // Handle errors if necessary
//     }
//   };

//   const handleChangeSingleStatus = async (itemId, id) => {
//     const confirmed = window.confirm("Are you sure you want to change the order status?");
//     if (!confirmed) {
//       return;
//     }
//     try {
//       const response = await postData("/order/updateStatus", {
//         selectedIds: itemId,
//         newStatusId: id,
//       });

//       if (response?.success) {
//         await getDataAll();
//         setShowSuccessModel(true);
//         GetAllOrdderStatus();
//         await setSelectAllChecked([]);
//         setTimeout(() => {
//           setShowSuccessModel(false);
//         }, 3000);
//       }
//     } catch (error) {
//       console.error("Error updating order status:", error);
//       alert("Failed to update the order status. Please try again later.");
//     }
//   };

//   const [user, setUser] = useState({});
//   const GetUserData = async () => {
//     const response = await getData(`/usersingleget`);
//     if (response?.success) {
//       setUser(response?.data);
//     }
//   };
//   useEffect(() => {
//     GetUserData();
//   }, []);

//   const HandleDownload = async () => {
//     // if (selectAllChecked.length == 0) {
//     //   alert("Please Select Atleast One Record");
//     // } else {
//     try {
//       await getDownloadDataExcel(
//         "/order/download",
//         { ids: selectAllChecked, from: startDate, to: endDate },
//         "Order List"
//       );
//     } catch (error) {
//       console.error("Error:", error);
//     }
//     // }
//   };
//   const [activeTab, setActiveTab] = useState("Ordered");

//   const handleTabClick = (tabName) => {
//     setActiveTab(tabName);
//   };
//   return (
//     <>
//       <div className="main-advancedashboard">
//         <Header title={"Orders"} link={"/employee/employee_details"} />
//         <section className="AdvanceDashboard">
//           <div className="col-xxl-12 col-xl-12  ">
//             <div className="row MainRowsec">
//               <section className="Tabels tab-radio tab-radio">
//                 <div className="">
//                   <div className="order-tab-holder">
//                     <Nav>
//                       {orderStatus && orderStatus?.map((status, index) =>
//                         <Nav.Item >
//                           <Nav.Link key={index}
//                             className={status?.value === searchOrderStatus?.value ? "active" : ""}
//                             onClick={() => {
//                               setSearchOrderSatatus(status);
//                               setSearchPaymentSatatus("");
//                               // setSearchOrderSatatus("");
//                               setSearchProduct("");
//                               setCustomerName("");
//                               setsearchInvoice("");
//                               setStartDate("");
//                               setEndDate("");
//                               setReset(!reset);
//                             }
//                             }
//                           >
//                             <span>{status?.count}</span> {status?.label}
//                           </Nav.Link>
//                         </Nav.Item>
//                       )}
//                     </Nav>
//                   </div>
//                   {/* container */}
//                   <div className="row">
//                     <div className="d-flex">
//                       <div className="add me-3">
//                         {/* {isAllow.includes(6) ? ( */}
//                         {/* <Link
//                           // to="/employee/employee_details/add"
//                           type="button"
//                           className="btn btn-add pe-3"
//                         >
//                           <div onClick={() => handleShow()}>
//                             <img src={plus} className="plus me-2 ms-0" alt="" />
//                             Add Product
//                           </div>
//                         </Link> */}
//                         {/* ) : (
//                         <></>
//                       )} */}
//                       </div>

//                       <div className="add">
//                         <div className="dropdown">
//                           <button
//                             className="btn btn-columns dropdown-toggle"
//                             type="button"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                           >
//                             <img
//                               src={colunms}
//                               className="columns me-2 "
//                               alt=""
//                             />
//                             Column Selection{" "}
//                             <img src={top} className="top ms-1" alt="" />{" "}
//                           </button>
//                           <ul className="dropdown-menu">
//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col1")}
//                                 href="#"
//                               >
//                                 Sr. No.
//                                 {visible?.col1 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col2")}
//                                 href="#"
//                               >
//                                 Invoice No.
//                                 {visible?.col2 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col3")}
//                                 href="#"
//                               >
//                                 Customer Name
//                                 {visible?.col3 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>

//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col4")}
//                                 href="#"
//                               >
//                                 Order Date
//                                 {visible?.col4 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>

//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col5")}
//                                 href="#"
//                               >
//                                 Delivery Date
//                                 {visible?.col5 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>

//                             {/* <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "col15")
//                                 }
//                                 href="#"
//                               >
//                                 Oreder Type
//                                 {visible?.col15 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li> */}

//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "col16")
//                                 }
//                                 href="#"
//                               >
//                                 User Type
//                                 {visible?.col16 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>

//                             {/*  <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col6")}
//                                 href="#"
//                               >
//                                 Product Name
//                                 {visible?.col5 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-2"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>

//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "col11")
//                                 }
//                                 href="#"
//                               >
//                                 Product Quantity
//                                 {visible?.col11 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>
//                              */}

//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col7")}
//                                 href="#"
//                               >
//                                 Total
//                                 {visible?.col7 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col8")}
//                                 href="#"
//                               >
//                                 Order Status
//                                 {visible?.col8 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) => toggleColumn(event, "col9")}
//                                 href="#"
//                               >
//                                 Payment Mode
//                                 {visible?.col9 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>

//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "customer_type")
//                                 }
//                                 href="#"
//                               >
//                                 Customer Type
//                                 {visible?.customer_type ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>

//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "repeat")
//                                 }
//                                 href="#"
//                               >
//                                 Repeat
//                                 {visible?.repeat ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>

//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "nxt_after_days")
//                                 }
//                                 href="#"
//                               >
//                                 Next After Days
//                                 {visible?.nxt_after_days ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>
//                             {/* <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "col10")
//                                 }
//                                 href="#"
//                               >
//                                 Payment Status
//                                 {visible?.col10 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li> */}
//                             {/*
//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "col12")
//                                 }
//                                 href="#"
//                               >
//                                 Delivery Boy
//                                 {visible?.col12 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li> */}
//                             <li>
//                               <Link
//                                 className="dropdown-item"
//                                 onClick={(event) =>
//                                   toggleColumn(event, "col13")
//                                 }
//                                 href="#"
//                               >
//                                 Action
//                                 {visible?.col13 ? (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye"
//                                   />
//                                 ) : (
//                                   <FontAwesomeIcon
//                                     className="ms-4"
//                                     icon="fa-solid fa-eye-slash"
//                                   />
//                                 )}
//                               </Link>
//                             </li>
//                           </ul>
//                           {/*  {usertype !== "Admin" ? ( */}
//                           <button
//                             className="btn btn-columns dropdown-toggle"
//                             type="button"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                             style={{
//                               marginLeft: "5px",
//                               backgroundColor: "green",
//                             }}
//                           >
//                             <img
//                               src={colunms}
//                               className="columns me-2 "
//                               alt=""
//                             />
//                             Change Order Status{" "}
//                             <img src={top} className="top ms-1" alt="" />{" "}
//                           </button>
//                           {/* ) : (<></>
//                           )} */}
//                           <div
//                             className="dropdown-menu"
//                             aria-labelledby="dropdownMenuButton"
//                           >
//                             {Object.keys(orderStatus).length > 0 &&
//                               Object.entries(orderStatus).map(
//                                 ([id, status]) =>
//                                   status?.value !== 1 && (
//                                     <button
//                                       className="dropdown-item"
//                                       key={id}
//                                       onClick={() =>
//                                         handleChangeStatus(status.value)
//                                       }
//                                     >
//                                       {status.label}{" "}
//                                       {/* Accessing the label property */}
//                                     </button>
//                                   )
//                               )}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="border-line mt-3"></div>
//                     <div className="row mt-4">
//                       <div className="col-xxl-2 col-xl-2 col-lg-12 col-md-3 col-sm-4 col-12">
//                         <div className="d-flex">
//                           <div className="show me-2">
//                             <p className="show mt-1">Show</p>
//                           </div>
//                           <div className="number me-2">
//                             <select
//                               className="form-select form-select-sm"
//                               aria-label=".form-select-sm example"
//                               onChange={(e) => setperPage(e.target.value)}
//                             >
//                               {option?.map((option) => (
//                                 <option key={option} value={option}>
//                                   {option}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                           <div className="entries">
//                             <p className="show mt-1">entries</p>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-xxl-10 col-xl-10  col-lg-12  col-md-12 col-12">
//                         <div className="row">
//                           <div className="col-xxl-2 col-xl-2  col-lg-3  col-md-6 col-sm-10 col-12 mt-xl-0 mt-3">
//                             <div className="num me-2">
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Invoice No"
//                                 id=""
//                                 value={searchInvoice}
//                                 onChange={(e) => {
//                                   setsearchInvoice(e.target.value);
//                                 }}
//                               />
//                             </div>
//                           </div>
//                           <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-6 col-sm-12 col-12 mt-xl-0 mt-3">
//                             <div className="num me-2">
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Customer Name"
//                                 id=""
//                                 value={customerName}
//                                 onChange={(e) => {
//                                   setCustomerName(e.target.value);
//                                 }}
//                               />
//                             </div>
//                           </div>
//                           {/* <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-6 col-sm-12 col-12 mt-xl-0 mt-3">
//                             <div className="num me-2">
//                               <Select
//                                 options={products}
//                                 placeholder="Select Product"
//                                 value={searchProduct}
//                                 onChange={(e) => {
//                                   setSearchProduct(e);
//                                 }}
//                               />
//                             </div>
//                           </div> */}
//                           {/* <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-6 col-sm-12 col-12 mt-xl-0 mt-3">
//                             <div className="num me-2">
//                               <Select
//                                 options={orderStatus}
//                                 placeholder="Select Order Status"
//                                 value={searchOrderStatus}
//                                 onChange={(e) => {
//                                   setSearchOrderSatatus(e);
//                                 }}
//                               />
//                             </div>
//                           </div> */}
//                           <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-6 col-sm-12 col-12 mt-xl-0  mt-3">
//                             <Form.Group className="form-group">
//                               <Form.Label>From : </Form.Label>
//                               <DatePicker
//                                 selected={startDate}
//                                 onChange={(date) => setStartDate(date)}
//                                 name="start_date"
//                                 id="start_date"
//                                 autoComplete="off"
//                                 dateFormat="dd/MM/yyyy" // Set date format
//                                 className="form-control"
//                                 placeholderText="DD/MM/YYYY" // Update placeholder
//                               />
//                             </Form.Group>
//                           </div>
//                           <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-6 col-sm-12 col-12 mt-xl-0 mt-3">
//                             <Form.Group className="form-group">
//                               <Form.Label>To : </Form.Label>
//                               <DatePicker
//                                 selected={endDate}
//                                 onChange={(date) => setEndDate(date)}
//                                 name="end_date"
//                                 id="end_date"
//                                 autoComplete="off"
//                                 dateFormat="dd/MM/yyyy" // Set date format
//                                 className="form-control"
//                                 placeholderText="DD/MM/YYYY" // Update placeholder
//                               />
//                             </Form.Group>
//                           </div>

//                           <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-6 col-sm-12 col-12  mt-3">
//                             <Form.Group
//                               className="form-group"
//                               onClick={HandleDownload}
//                             >
//                               <Form.Label>Download data in Excel : </Form.Label>
//                               <FontAwesomeIcon
//                                 icon="fa-solid fa-file-lines"
//                                 className="pdf-icon"
//                                 variant="success"
//                               // className="btn btn-view"
//                               />
//                             </Form.Group>
//                           </div>

//                           {/* <div className="col-xxl-2 col-xl-2  col-lg-3  col-md-6 col-sm-10 col-12 mt-xl-0 mt-3">
//                             <div className="num me-2">
//                               <Select
//                                 options={[
//                                   { label: "Paid", value: "1" },
//                                   { label: "Unpaid", value: "0" },
//                                 ]}
//                                 // value={searchPaymentStatus}
//                                 placeholder="Payment Status"
//                                 value={searchPaymentStatus}
//                                 onChange={(e) => {
//                                   setSearchPaymentSatatus(e);
//                                 }}
//                               />
//                             </div>
//                           </div> */}
//                           <div className="col-xxl-1 col-xl-1  col-lg-1  col-md-1 col-sm-2 col-3  mt-3">
//                             <div className="Search-1">
//                               <button
//                                 type="button"
//                                 onClick={() => {
//                                   setSearchPaymentSatatus("");
//                                   // setSearchOrderSatatus("");
//                                   setSearchProduct("");
//                                   setCustomerName("");
//                                   setsearchInvoice("");
//                                   setStartDate("");
//                                   setEndDate("");
//                                   setReset(!reset);
//                                 }}
//                                 className="btn btn-reset"
//                               >
//                                 Reset
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="border-line mt-3"></div>
//                   <div className="row mt-3">
//                     <div className="data table-responsive">
//                       <Table striped bordered hover responsive center>
//                         <thead>
//                           <tr className="">
//                             <th>
//                               {/* <input
//                                 type="checkbox"
//                                 value="selectAll"
//                                 checked={
//                                   allChecked.length === selectAllChecked.length
//                                 }
//                                 onChange={handleChange}
//                                 id="selectAll"
//                                 className="me-1"
//                               ></input> */}
//                               Select
//                             </th>
//                             {visible.col1 && <th className="sr tax-name" style={{ width: "50px" }}>Sr. No.</th>}
//                             {visible.col2 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Invoice No.
//                               </th>
//                             )}
//                             {visible.col3 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Customer Name
//                               </th>
//                             )}
//                             {visible.col4 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Order Date
//                               </th>
//                             )}
//                             {visible.col5 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Delivery Date
//                               </th>
//                             )}{" "}
//                             {/* {visible.col6 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Delivery Time
//                               </th>
//                             )}{" "} */}
//                             {/* {visible.col6 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Product Name
//                               </th>
//                             )}
//                             {visible.col11 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Product Quantity
//                               </th>
//                             )} */}
//                             {/* {visible.col15 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Order Type
//                               </th>
//                             )}{" "} */}
//                             {visible.col16 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Customer Type
//                               </th>
//                             )}{" "}
//                             {visible.col7 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Total
//                               </th>
//                             )}
//                             {visible.col8 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Order Status
//                               </th>
//                             )}
//                             {visible.col9 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Payment Mode
//                               </th>
//                             )}
//                             {visible.customer_type && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Customer Type
//                               </th>
//                             )}
//                             {visible.repeat && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Repeat
//                               </th>
//                             )}
//                             {visible.nxt_after_days && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Next After Days
//                               </th>
//                             )}
//                             {/* {visible.col10 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Payment Status
//                               </th>
//                             )} */}
//                             {/*
//                             {visible.col12 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Delivery Boy
//                               </th>
//                             )} */}
//                             {visible.col13 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "200px" }}
//                               >
//                                 Action
//                               </th>
//                             )}
//                             {visible.col14 && (
//                               <th
//                                 className="tax-name"
//                                 style={{ width: "500px" }}
//                               >
//                                 Shift
//                               </th>
//                             )}
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {/* {isAllow.includes(5) ? ( */}
//                           {data?.data?.data?.map((d, index) => {
//                             return (
//                               <tr className="" key={index}>
//                                 <td>
//                                   {d?.order_status_id === 4 ||
//                                     d?.order_status_id === 6 ? (
//                                     <></>
//                                   ) : (
//                                     <input
//                                       type="checkbox"
//                                       className="form-check-input"
//                                       value={d?.id}
//                                       name="perselected"
//                                       checked={selectAllChecked.includes(d?.id)}
//                                       onChange={handleChange}
//                                       id="flexCheckDefault"
//                                     ></input>
//                                   )}
//                                 </td>
//                                 {visible.col1 && <td>{++index}.</td>}
//                                 {visible.col2 && (
//                                   <td style={{ width: "200px" }}>
//                                     {d?.invoice_number}
//                                   </td>
//                                 )}
//                                 {visible.col3 && (
//                                   <td>
//                                     {d?.user?.first_name +
//                                       " " +
//                                       d?.user?.last_name}
//                                   </td>
//                                 )}
//                                 {visible.col4 && (
//                                   <td>{d?.createdAt.split("T")[0]}</td>
//                                 )}
//                                 {visible.col5 && (
//                                   <td>{d?.delivery_date?.split("T")[0]}</td>
//                                 )}
//                                 {/* {visible.col6 && (
//                                   <td>{d?.delivery_date?.split("T")[1]}</td>
//                                 )} */}

//                                 {/* {visible.col6 && (
//                                   <td>
//                                     <div key={index}>{d?.product?.name}</div>
//                                   </td>
//                                 )}
//                                 {visible.col11 && (
//                                   <td>
//                                     {d?.product_quantity?.quantity +
//                                       " " +
//                                       d?.product_quantity?.unit?.name}
//                                   </td>
//                                 )} */}
//                                 {/* {visible.col15 && <td>{d?.type}</td>} */}
//                                 {visible.col16 && <td>{d?.user?.type}</td>}
//                                 {visible.col7 && (
//                                   <td>
//                                     {/* {Number(d?.price ?? 0) +
//                                       (Number(d?.price ?? 0) *
//                                         Number(d?.tax_percent ?? 0)) /
//                                         100} */}

//                                     {d?.total}
//                                   </td>
//                                 )}
//                                 {visible.col8 && (
//                                   <td>{d?.order_status?.name}</td>
//                                 )}
//                                 {visible.col9 && <td>{d?.payment_mode}</td>}
//                                 {visible.customer_type && (
//                                   <td>{d?.customer_type}</td>
//                                 )}
//                                 {visible.repeat && <td>{d?.repeat}</td>}
//                                 {visible.nxt_after_days && (
//                                   <td>{d?.nxt_after_days}</td>
//                                 )}

//                                 {/* {visible.col10 && (
//                                   <td>
//                                     <div className="form-check form-switch">
//                                       <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         role="switch"
//                                         disabled={user.id === 1}
//                                         style={{
//                                           backgroundColor: d.payment_status
//                                             .status
//                                             ? "green"
//                                             : "red",
//                                         }}
//                                         checked={d.payment_status.status}
//                                         onChange={() => {
//                                           ChangeStatus(d.id);
//                                         }}
//                                         id={`flexSwitchCheckDefault${d?.id}`}
//                                       />
//                                       <label
//                                         className="form-check-label"
//                                         htmlFor={`flexSwitchCheckDefault${d?.id}`}
//                                       >
//                                         {d.payment_status.status
//                                           ? "Paid"
//                                           : "Unpaid"}
//                                       </label>
//                                     </div>
//                                   </td>
//                                 )} */}
//                                 {/*
//                                 {visible.col12 && (
//                                   <td>
//                                     {d?.order?.users_address?.delivery_boy}
//                                   </td>
//                                 )} */}

//                                 {/* {visible.col6 && <td>{d?.password}</td>} */}
//                                 {/* {visible.col6 && <td>******</td>} */}
//                                 {/* {visible.col7 && (
//                                   <td>
//                                     {d?.id === 1 ? (
//                                       <></>
//                                     ) : (
//                                       <div className="form-check form-switch">
//                                         <input
//                                           className="form-check-input"
//                                           type="checkbox"
//                                           role="switch"
//                                           checked={d.status}
//                                           onChange={() => {
//                                             ChangeStatus(d.id);
//                                           }}
//                                           id={`flexSwitchCheckDefault${d?.id}`}
//                                         />
//                                         <label
//                                           className="form-check-label"
//                                           htmlFor={`flexSwitchCheckDefault${d?.id}`}
//                                         >
//                                           {d.status ? "Active" : "Inactive"}
//                                         </label>
//                                       </div>
//                                     )}
//                                   </td>
//                                 )} */}
//                                 {visible.col13 && (
//                                   <td>
//                                     <div className="d-flex">
//                                       <Button
//                                         onClick={() => handleShow1(d?.id)}
//                                         type="button"
//                                         className="btn btn-primary me-1"
//                                       >
//                                         <FontAwesomeIcon icon="fa-solid fa-eye" />
//                                         {/* <img src={pen} className="pen" alt="" /> */}
//                                       </Button>

//                                     </div>
//                                   </td>
//                                 )}
//                                 {visible.col14 && (
//                                   <td>
//                                     <div className="d-flex">
//                                       <Button
//                                         onClick={() => handleChangeSingleStatus(d?.id, d?.order_status_id + 1)}
//                                         type="button"
//                                         className="btn btn-primary me-1"
//                                       >
//                                         To {" "}
//                                         {
//                                           // Find the next order status name based on the current order_status_id + 1
//                                           orderStatuses.find(status => status.value === (d?.order_status_id + 1))?.label || "N/A"
//                                         }
//                                       </Button>

//                                     </div>
//                                   </td>
//                                 )}
//                               </tr>
//                             );
//                           })}
//                         </tbody>
//                       </Table>
//                     </div>
//                   </div>
//                 </div>
//               </section>
//             </div>
//           </div>
//         </section>
//       </div>

//       {show ? (
//         <AddOffCanvance
//           handleClose={handleClose}
//           setShow={setShowAdd}
//           show={show}
//         />
//       ) : (
//         ""
//       )}

//       {show1 ? (
//         <EditOffCanvance
//           handleClose={handleClose1}
//           setShow={setShowEdit}
//           show={show1}
//         />
//       ) : (
//         ""
//       )}

//       <Toaster position="top-right" />

//       {/* <!-- Modal Delete --> */}
//       <div className="upload-modal">
//         <div
//           className={`modal fade ${showDeleteModal ? "show" : ""}`}
//           style={{ display: showDeleteModal ? "block" : "none" }}
//           id="exampleModaldel"
//           tabIndex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog  modal-dialog-centered">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <h1 className="add-success text-center  mt-2">
//                   Are you sure ?
//                 </h1>
//                 <p>
//                   Do You Really Want to Delete These Record?
//                   <br /> This Process CanNot Be Undone{" "}
//                 </p>
//                 <div className="button-holder text-center mt-2">
//                   <button className="btn btn-yes me-2" onClick={DeleteRecord}>
//                     Yes
//                   </button>
//                   <button className="btn btn-no" onClick={handleDeleteCancel}>
//                     No
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Successfull_Modal
//         show={showSuccessModel}
//         message={"Status Changed Successfully"}
//         onHide={() => setShowSuccessModel(false)}
//       />
//     </>
//   );
// };

// export default Tables;

import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Nav,
  Tab,
} from "react-bootstrap";
import { Context } from "../../../utils/context";
import { post } from "jquery";
import { getDownloadDataExcel, postData } from "../../../utils/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Successfull_Modal from "../../common/Successfull_Modal/Successfull_Modal";
import Pending_Table from "./Pending_Table/Pending_Table";
import Sent_Table from "./Sent_Table/Sent_Table";
import Cancelled_Table from "./Cancelled_Table/Cancelled_Table";
import Unshipped_table from "./Unshipped_table/Unshipped_table";
library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    Select2Data,
    usertype,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState({ value: 25, label: "Results per page: 25" });
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [customerType, setCustomerType] = useState("");
  const [sortOrder, setSortOrder] = useState({ value: "DESC", label: "Sort-by date (Descending)" });
  const [hideFilter, setHideFilter] = useState(false);

  const [changeStatus, setChangeStatus] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [searchInvoice, setsearchInvoice] = useState("");
  const [searchProduct, setSearchProduct] = useState();
  const [searchOrder, setSearchOder] = useState();
  const [searchOrderStatus, setSearchOrderSatatus] = useState(2);
  const [searchPaymentStatus, setSearchPaymentSatatus] = useState("");

  //
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const getDataAll = async () => {
    const response = await getData(
      `/order?page=${currentPage}&per_page=${perPage?.value || 25}&term=${search}&customerName=${customerName}&searchProduct=${searchProduct?.value || ""
      }&searchOrderStatus=${searchOrderStatus || ""
      }&searchPaymentStatus=${searchPaymentStatus?.value || ""
      }&searchInvoice=${searchInvoice}&from=${startDate}&to=${endDate}&customerType=${customerType || ""
      }&sortOrder=${sortOrder?.value || 'DESC'}`

    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    // setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    // await GetAllOrdderStatus();
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    // perPage,
    reset,
    show,
    show1,
    customerName,
    searchProduct,
    searchOrderStatus,
    searchPaymentStatus,
    searchInvoice,
    startDate,
    endDate,
    customerType,
    searchOrder,
    // sortOrder
  ]);

  console.log("searchOrderStatus", searchOrderStatus);

  useEffect(() => { }, [searchPaymentStatus]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/order/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const [searchStatus, setSearchStatus] = useState({});

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const [showSuccessModel, setShowSuccessModel] = useState(false);

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/order/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
    col14: true,
    col15: true,
    col16: true,
    customer_type: true,
    repeat: true,
    nxt_after_days: true,
  });

  const shipByDateOptions = [
    { value: "ASC", label: "Sort-by date (Ascending)" },
    { value: "DESC", label: "Sort-by date (Descending)" },
  ];

  const resultsPerPageOptions = [
    { value: 25, label: "Results per page: 25" },
    { value: 50, label: "Results per page: 50" },
    { value: 100, label: "Results per page: 100" },
  ];

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };
  const [products, setProducts] = useState({});
  const [orderStatus, setOrderStatus] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);



  const GetAllProducts = async () => {
    const response = await getData("/all-products");

    if (response?.success) {
      setProducts(await Select2Data(response?.data, "product_id"));
    }
  };

  const Select3Data = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.id,
      label: data?.name,
      name: name,
      count: data?.count,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };
  const [statusCount, setStatusCount] = useState({});

  const GetAllOrdderStatus = async () => {
    const response = await getData("/all-count-order-status");
    setStatusCount(response?.data)
  };

  const GetAllOrdderStatuses = async () => {
    const response = await getData("/all-order-status");

    if (response?.success) {
      setOrderStatuses(await Select2Data(response?.data, "order_status_id"));
    }
  };

  useEffect(() => {
    GetAllProducts();
    GetAllOrdderStatus();
    GetAllOrdderStatuses();
  }, []);

  useEffect(() => {
    // console.log("selectAllChecked", selectAllChecked);
  }, [selectAllChecked]);


  const [user, setUser] = useState({});

  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const HandleDownload = async () => {
    // if (selectAllChecked.length == 0) {
    //   alert("Please Select Atleast One Record");
    // } else {
    try {
      await getDownloadDataExcel(
        "/order/download",
        { ids: selectAllChecked, from: startDate, to: endDate },
        "Order List"
      );
    } catch (error) {
      console.error("Error:", error);
    }
    // }
  };

  const handleCancelOrer = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to Cancel order?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const response = await getData(`/order/cancel-order/${id}`);

      if (response?.success) {
        await getDataAll();
        setShowSuccessModel(true);
        GetAllOrdderStatus();
        await setSelectAllChecked([]);
        setTimeout(() => {
          setShowSuccessModel(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error Cancelling order status:", error);
      alert("Failed to update the order status. Please try again later.");
    }
  };

  const downloadInvoice = (invoiceUrl) => {
    // Make a GET request to the backend to trigger the download
    const link = document.createElement("a");
    link.href = invoiceUrl;  // The URL of the invoice file on the server
    link.target = "_blank";
    link.download = true; // This ensures the browser triggers a download
    link.click();
  };


  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Orders"} link={"/employee/employee_details"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio">
                <div className="">
                  <div className="order-tab-holder">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Unshipped"
                    >
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills" className="mb-3">
                            <Nav.Item>
                              <Nav.Link eventKey="Pending" onClick={() => setSearchOrderSatatus(1)}>
                                <span>{statusCount?.Pending}</span> Pending
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="Unshipped" onClick={() => setSearchOrderSatatus(2)}>
                                {" "}
                                <span>{statusCount?.Unshipped}</span> Unshipped
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                              <Nav.Link eventKey="Cancelled" onClick={() => setSearchOrderSatatus(6)}>
                                {" "}
                                <span>{statusCount?.Cancelled}</span> Cancelled
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                              <Nav.Link eventKey="Sent" onClick={() => setSearchOrderSatatus(3)}>
                                {" "}
                                <span>{statusCount?.PickupScheduled + statusCount?.Shipped}</span> Sent
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="Pending">
                              <Pending_Table
                                data={data}
                                handleCancelOrer={handleCancelOrer}
                                getDataAll={getDataAll}
                                statusCount={statusCount}
                                customerType={customerType}
                                setCustomerType={setCustomerType}
                                shipByDateOptions={shipByDateOptions}
                                sortOrder={sortOrder}
                                setSortOrder={setSortOrder}
                                resultsPerPageOptions={resultsPerPageOptions}
                                perPage={perPage}
                                setperPage={setperPage}
                                setReset={setReset}
                                hideFilter={hideFilter}
                                setHideFilter={setHideFilter}
                                option={option}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Unshipped">
                              <Unshipped_table
                                data={data}
                                handleCancelOrer={handleCancelOrer}
                                getDataAll={getDataAll}
                                statusCount={statusCount}
                                customerType={customerType}
                                setCustomerType={setCustomerType}
                                shipByDateOptions={shipByDateOptions}
                                sortOrder={sortOrder}
                                setSortOrder={setSortOrder}
                                resultsPerPageOptions={resultsPerPageOptions}
                                perPage={perPage}
                                setperPage={setperPage}
                                setReset={setReset}
                                downloadInvoice={downloadInvoice}
                                hideFilter={hideFilter}
                                setHideFilter={setHideFilter}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Cancelled">
                              <Cancelled_Table
                                data={data}
                                handleCancelOrer={handleCancelOrer}
                                getDataAll={getDataAll}
                                statusCount={statusCount}
                                customerType={customerType}
                                setCustomerType={setCustomerType}
                                shipByDateOptions={shipByDateOptions}
                                sortOrder={sortOrder}
                                setSortOrder={setSortOrder}
                                resultsPerPageOptions={resultsPerPageOptions}
                                perPage={perPage}
                                setperPage={setperPage}
                                setReset={setReset}
                                hideFilter={hideFilter}
                                setHideFilter={setHideFilter}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Sent">
                              <Sent_Table
                                data={data}
                                setSearchOrderSatatus={setSearchOrderSatatus}
                                handleCancelOrer={handleCancelOrer}
                                statusCount={statusCount}
                                getDataAll={getDataAll}
                                resultsPerPageOptions={resultsPerPageOptions}
                                customerType={customerType}
                                setCustomerType={setCustomerType}
                                setSortOrder={setSortOrder}
                                perPage={perPage}
                                setperPage={setperPage}
                                setReset={setReset}
                                downloadInvoice={downloadInvoice}
                                hideFilter={hideFilter}
                                setHideFilter={setHideFilter}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>

                  <div className=""></div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Successfull_Modal
        show={showSuccessModel}
        message={"Status Changed Successfully"}
        onHide={() => setShowSuccessModel(false)}
      />
    </>
  );
};

export default Tables;
