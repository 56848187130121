
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import {
  Row,
  Col,
  Nav,
  Tab,
} from "react-bootstrap";
import { Context } from "../../../utils/context";
import { getDownloadDataExcel, postData } from "../../../utils/api";
import "react-datepicker/dist/react-datepicker.css";
import Successfull_Modal from "../../common/Successfull_Modal/Successfull_Modal";
import Sent_Table from "./Sent_Table/Sent_Table";
import { useParams } from "react-router-dom";
library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const { id } = useParams()
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    Select2Data,
    usertype,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState({ value: 25, label: "Results per page: 25" });
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [customerType, setCustomerType] = useState("");
  const [sortOrder, setSortOrder] = useState({ value: "DESC", label: "Sort-by date (Descending)" });
  const [hideFilter, setHideFilter] = useState(false);

  const [changeStatus, setChangeStatus] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [searchOrder, setSearchOder] = useState();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const getDataAll = async () => {
    const response = await getData(
      `/order?page=${currentPage}&per_page=${perPage?.value || 25}&term=${search}&searchOrder=${id || ""}`

    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    // setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    // await GetAllOrdderStatus();
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    // perPage,
    reset,
    show,
    show1,
    startDate,
    endDate,
    customerType,
    searchOrder,
    // sortOrder
  ]);


  useEffect(() => { setSearchOder(id) }, [id]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/order/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const [searchStatus, setSearchStatus] = useState({});

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const [showSuccessModel, setShowSuccessModel] = useState(false);

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/order/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
    col14: true,
    col15: true,
    col16: true,
    customer_type: true,
    repeat: true,
    nxt_after_days: true,
  });

  const shipByDateOptions = [
    { value: "ASC", label: "Sort-by date (Ascending)" },
    { value: "DESC", label: "Sort-by date (Descending)" },
  ];

  const resultsPerPageOptions = [
    { value: 25, label: "Results per page: 25" },
    { value: 50, label: "Results per page: 50" },
    { value: 100, label: "Results per page: 100" },
  ];

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };
  const [products, setProducts] = useState({});
  const [orderStatus, setOrderStatus] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);



  const GetAllProducts = async () => {
    const response = await getData("/all-products");

    if (response?.success) {
      setProducts(await Select2Data(response?.data, "product_id"));
    }
  };

  const Select3Data = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.id,
      label: data?.name,
      name: name,
      count: data?.count,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };
  const [statusCount, setStatusCount] = useState({});

  const GetAllOrdderStatus = async () => {
    const response = await getData("/all-count-order-status");
    setStatusCount(response?.data)
  };

  const GetAllOrdderStatuses = async () => {
    const response = await getData("/all-order-status");

    if (response?.success) {
      setOrderStatuses(await Select2Data(response?.data, "order_status_id"));
    }
  };

  useEffect(() => {
    GetAllProducts();
    GetAllOrdderStatus();
    GetAllOrdderStatuses();
  }, []);

  useEffect(() => {
    // console.log("selectAllChecked", selectAllChecked);
  }, [selectAllChecked]);


  const [user, setUser] = useState({});

  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const HandleDownload = async () => {
    // if (selectAllChecked.length == 0) {
    //   alert("Please Select Atleast One Record");
    // } else {
    try {
      await getDownloadDataExcel(
        "/order/download",
        { ids: selectAllChecked, from: startDate, to: endDate },
        "Order List"
      );
    } catch (error) {
      console.error("Error:", error);
    }
    // }
  };

  const handleCancelOrer = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to Cancel order?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const response = await getData(`/order/cancel-order/${id}`);

      if (response?.success) {
        await getDataAll();
        setShowSuccessModel(true);
        GetAllOrdderStatus();
        await setSelectAllChecked([]);
        setTimeout(() => {
          setShowSuccessModel(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error Cancelling order status:", error);
      alert("Failed to update the order status. Please try again later.");
    }
  };

  const downloadInvoice = (invoiceUrl) => {
    // Make a GET request to the backend to trigger the download
    const link = document.createElement("a");
    link.href = invoiceUrl;  // The URL of the invoice file on the server
    link.target = "_blank";
    link.download = true; // This ensures the browser triggers a download
    link.click();
  };


  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Orders"} link={"/employee/employee_details"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio">
                <div className="">
                  <div className="order-tab-holder">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Pending"
                    >
                      <Row>
                        <Col sm={12}>
                          <Sent_Table
                            data={data}
                            statusCount={statusCount}
                            getDataAll={getDataAll}
                            resultsPerPageOptions={resultsPerPageOptions}
                            customerType={customerType}
                            setCustomerType={setCustomerType}
                            setSortOrder={setSortOrder}
                            perPage={perPage}
                            setperPage={setperPage}
                            setReset={setReset}
                            downloadInvoice={downloadInvoice}
                            hideFilter={hideFilter}
                            setHideFilter={setHideFilter}
                          />
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>

                  <div className=""></div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Successfull_Modal
        show={showSuccessModel}
        message={"Status Changed Successfully"}
        onHide={() => setShowSuccessModel(false)}
      />
    </>
  );
};

export default Tables;
