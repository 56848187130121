import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "./Pending_Table.css"
import Select from "react-select";
import { Table, Button, Form } from "react-bootstrap";
import { Context } from "../../../../utils/context";

function Pending_Table({
  data, handleCancelOrer, getDataAll, statusCount, customerType, setCustomerType, shipByDateOptions,
  sortOrder, setSortOrder, resultsPerPageOptions, perPage, setperPage, setReset, hideFilter, setHideFilter, option
}) {

  const {
    IMG_URL
  } = useContext(Context);

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "20px",
      borderColor: "#ddd",
      boxShadow: "none",
      fontSize: "15px",
      "&:hover": {
        borderColor: "#aaa",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#000",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff",
      color: "#000",
      fontSize: "15px",
      "&:hover": {
        backgroundColor: "#eee",
      },
    }),
  };

  const calculateTimeAgo = (date) => {
    const createdAt = new Date(date);
    const now = new Date();
    const diffInMilliseconds = now - createdAt;

    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (diffInDays >= 1) {
      return `${diffInDays} Day${diffInDays > 1 ? 's' : ''} Ago`;
    } else if (diffInHours >= 1) {
      return `${diffInHours} Hour${diffInHours > 1 ? 's' : ''} Ago`;
    } else {
      return `${diffInMinutes} Min Ago`;
    }
  };

  console.log("option", option);

  const formatTimeInIST = (date) => {
    const createdAt = new Date(date);
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    const istTime = createdAt.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', ...options });
    // setFormattedTime(`${istTime} IST`);
    return istTime
  };
  return (
    <section className="pending-table_holder">
      {/* Quick Filters Section */}
      <div className="quick-filters">
        <span className="quick-filters__label">Quick Filters:</span>
        <div className="quick-filters__tabs">
          <Button
            className={customerType === '' ? "quick-filters__tab quick-filters__tab--active" : "quick-filters__tab"}
            onClick={() => setCustomerType("")}
          >
            All Customers
          </Button>
          <Button
            className={customerType === 'B2B Customer' ? "quick-filters__tab quick-filters__tab--active" : "quick-filters__tab"}
            onClick={() => setCustomerType("B2B Customer")}
          >
            B2B Customer
          </Button>
          <Button
            className={customerType === 'B2C Customer' ? "quick-filters__tab quick-filters__tab--active" : "quick-filters__tab"}
            onClick={() => setCustomerType("B2C Customer")}
          >B2C Customer
          </Button>
          {/* <Button className="quick-filters__tab">
            Business customer unshipped
          </Button>
          <Button className="quick-filters__tab">Verge of Late Shipment</Button>
          <Button className="quick-filters__tab">Verge of Cancellation</Button> */}
        </div>
      </div>

      <div className="filters-section">
        <div className="filters-section__label">
          <Button className="hide_btn"
            onClick={() => {
              setHideFilter(!hideFilter)
              setCustomerType("");
              setSortOrder({ value: "DESC", label: "Sort-by date (Descending)" });
              setperPage({ value: 25, label: "Results per page: 25" });
              setReset()
            }}
          > {!hideFilter ? "Hide Filter" : "Show Filter"}
          </Button>
          <h6>
            <span>{statusCount?.Pending} Order</span>
            {/* Last 30 Days */}
          </h6>
        </div>
        {!hideFilter &&
          <div className="filters-section__controls">
            <Select
              options={shipByDateOptions}
              // defaultValue={shipByDateOptions[0]}
              value={sortOrder}
              onChange={(e) => setSortOrder(e)}
              className="filters-section__dropdown"
              classNamePrefix="react-select"
              styles={customSelectStyles}
            />
            {/* Results per page dropdown */}
            <Select
              options={resultsPerPageOptions}
              // defaultValue={resultsPerPageOptions[0]}
              value={perPage}
              onChange={(e) => setperPage(e)}
              className="filters-section__dropdown"
              classNamePrefix="react-select"
              styles={customSelectStyles}
            />

            <Button
              variant="outline-secondary"
              className="filters-section__btn active"
              onClick={getDataAll}
            >
              Set Table Preferences
            </Button>
            <Button variant="outline-secondary" className="filters-section__btn"
              onClick={() => {
                setCustomerType("");
                setSortOrder({ value: "DESC", label: "Sort-by date (Descending)" });
                setperPage({ value: 25, label: "Results per page: 25" });
                setReset()
              }}
            >
              Refresh
            </Button>
          </div>
        }
      </div>

      {/* Table Section */}
      <div className="pending-table ">
        <Table striped bordered hover className="order-table" responsive="xl">
          <thead>
            <tr>
              {/* <th>
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                />
              </th> */}
              <th>Order Date</th>
              <th>Order details</th>
              <th>Image</th>
              <th>Product name</th>
              <th>Order type</th>
              <th>Order Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

            {data?.data?.data?.map((item, index) =>
              <tr key={index}>
                {/* <td>
                  <Form.Check // prettier-ignore
                    type={"checkbox"}
                  />
                </td> */}
                <td className="order-date">
                  <div className="order-id">{calculateTimeAgo(item?.createdAt)}</div>
                  <div className="order-info">
                    <span>{new Date(item?.createdAt).toISOString().split('T')[0]}</span>
                  </div>
                  <div className="order-info">
                    <span>{formatTimeInIST(item?.createdAt)} IST</span>
                  </div>
                </td>

                <td className="order-details">
                  <div className="order-id text-highlight">
                    {/* 404-8206376-0718713 */}
                    {item?.invoice_number}
                  </div>
                  <div className="order-info">
                    <span>Buyer Name</span>
                  </div>
                  <div className="order-info">
                    <span className="text-highlight">{item?.user?.first_name} {item?.user?.last_name}</span>
                  </div>
                  {/* <div className="order-info">
                    <span>Fulfillment method: {item?.user?.type}</span>
                  </div> */}
                  {/* <div className="order-info">
                    <span>Sales channel: Amazon.in</span>
                  </div> */}

                  {/* <div className="order-info">
                    <span className="text-highlight">✓prime</span>
                  </div> */}

                  <div className="order-info">
                    <span>{item?.payment_mode === 'cod' ? 'Cash On Delivery' : 'Online'}</span>
                  </div>

                  <div className="order-info">
                    <span>{item?.user?.type}</span>
                  </div>
                </td>
                <td>
                  <img
                    src={item?.orders_details ? IMG_URL + item?.orders_details[0]?.product?.image : ""}
                    alt="Product"
                    className="product-image"
                  />
                </td>
                <td className="product-name">
                  {item?.orders_details?.map((pro) =>
                    <>
                      <div className="order-id text-highlight ">
                        {pro?.product?.name}
                        <span>{pro?.product?.short_description}</span>
                      </div>

                      <div className="order-info">
                        <span>
                          HSN: <b>{pro?.product?.hsn_code}</b>
                        </span>
                      </div>

                      <div className="order-info">
                        <span>SKU: {pro?.product?.sku_id}</span>
                      </div>

                      <div className="order-info">
                        <span>Quantity: {pro?.quantity}</span>
                      </div>


                    </>
                  )}
                  <div className="order-info">
                    <span> subtotal: {parseFloat(item?.total)}</span>
                  </div>
                </td>
                <td>
                  <div className="order-id">
                    <span> {item?.orders_details ? item?.orders_details[0]?.type : ""}</span>
                  </div>
                  {/* <div className="order-info">
                    <span>Ship by date: Sun, 10 Nov, 2024 IST</span>
                  </div>
                  <div className="order-info">
                    <span>Deliver by date: Mon, 11 Nov, 2024 IST</span>
                  </div> */}
                </td>
                <td>
                  {item?.order_status_id === 4 ?
                    <div>
                      <p className="status-holder shipped">{item?.order_status?.name}</p>
                    </div>
                    :
                    <div>
                      <p className="status-holder Unshipped">{item?.order_status?.name}</p>
                    </div>
                  }

                </td>
                <td>
                  {/* <div className="my-1">
                    <Button className="action-btn active">Schedule Pickup</Button>
                  </div> */}

                  {/* <div className="my-1">
                    <Button className="action-btn">Print Packing Slip</Button>
                  </div>

                  <div className="my-1">
                    <Button className="action-btn">Print Tax Invoice</Button>
                  </div> */}

                  <div className="my-1">
                    <Button className="action-btn" onClick={() => handleCancelOrer(item?.id)}>Cancel Order</Button>
                  </div>
                </td>
              </tr>
            )}

          </tbody>
        </Table>
      </div>
    </section>
  );
}

export default Pending_Table;
