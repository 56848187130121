import React, { useContext, useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Radio, Select } from "@mui/material";

library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDownloadDataExcel } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // Correct useState for year and month
  const [error, setError] = useState("");
  const [month, setMonth] = useState(null);
  const [transactionType, setTransactionType] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const HandleDownload = async () => {
    if (!month || !transactionType) {
      setError("Month and Transaction Type are required!");
      return;
    }

    try {
      const data = {
        month: month,
        transaction_type: transactionType
      };
      const response = await getDownloadDataExcel("/salesreport/download", data, "Sales report");
      console.log(response);

      setTimeout(() => {
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Download Excel
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(HandleDownload)} role="form">
            <Row className="justify-content-center">

              {/* Month Selection */}
              <Col md={4}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Select Month</Form.Label>
                    </div>

                    <DatePicker
                      selected={month}
                      onChange={(date) => setMonth(date)} // Handle month change
                      showMonthYearPicker // Only show the month and year
                      dateFormat="MMMM yyyy" // Display the month and year
                      className={classNames("form-control", {
                        "is-invalid": errors?.month,
                      })}
                    />
                    {errors?.month && (
                      <div className="invalid-feedback">
                        Month is required
                      </div>
                    )}
                  </Form.Group>
                </div>
              </Col>

              {/* Transaction Type Selection */}
              <Col md={4}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Include Transaction</Form.Label>
                    </div>
                    <div className="d-flex flex-row">
                      {/* Using Form.Check for Radio Buttons */}
                      <Form.Check
                        className="ms-5"
                        type="radio"
                        label="BTB"
                        name="transactionType"
                        value="B2B Customer"
                        id="btb-radio"
                        onChange={(e) => setTransactionType(e.target.value)}
                        checked={transactionType === "B2B Customer"}
                      />
                      <Form.Check
                        className="ms-2"
                        type="radio"
                        label="BTC"
                        name="transactionType"
                        value="B2C Customer"
                        id="btc-radio"
                        onChange={(e) => setTransactionType(e.target.value)}
                        checked={transactionType === "B2C Customer"}
                      />
                    </div>
                    {errors?.transactionType && (
                      <div className="invalid-feedback">
                        Transaction type is required
                      </div>
                    )}
                  </Form.Group>
                </div>
              </Col>
              {error && <span className="text-danger text-center mt-3">{error}</span>}

              {/* Submit Section */}
              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <Button
                    name={"save"}
                    onClick={handleSubmit(HandleDownload)}
                  // disabled={!month || !transactionType} // Disable button if month or transactionType is not set
                  >
                    Download
                  </Button>
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for showing success/error */}
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
