import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetOrderDetails } from "../../../utils/apis/orders/orders";
import { getData } from "../../../utils/api";
library.add(fas);

const EditOffCanvance = (props) => {

  console.log("show1", props.show);

  const { IMG_URL } = useContext(Context);
  const [details, setDetails] = useState([]);
  const [history, setHistory] = useState([]);

  const [totalPages, settotalPages] = useState();
  const [onPageChange, setonPageChange] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [option, setOption] = useState();

  const getSingleData = async () => {
    const res = await getData(`/employee/customers/${props.show}`);
    setDetails(res?.data);
    const res1 = await getData(`/employee/customers/purchase-history/${props.show}?page=${onPageChange}&per_page=${perPage}`);
    setHistory(res1)
    setCurrentPage(res1?.data?.currentPage);
    setperPage(res1?.data?.per_page);
    // setSearch(res1?.data?.search_name);
    // setOption(await Per_Page_Dropdown(res1?.data?.totalEntries));
    settotalPages(res1?.data?.totalPages);
  };

  useEffect(() => {
    getSingleData();
  }, [perPage, onPageChange]);

  const handlePageChange = (pageNumber) => {
    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    if (props.show) {
      getSingleData();
    }
  }, [props.show]);
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Customer Details</Card.Title>
              <hr />
              <Container>
                <div className="cattable_div">
                  <div className="cattable_main">
                    <Row className="d-flex flex-row">
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Form.Group className="d-flex flex-column">
                            <Row className="align-items-center">
                              <Col xs="auto">
                                <Form.Label>Name:</Form.Label>
                              </Col>
                              <Col className="mt-2">
                                <p>{details?.first_name} {details?.last_name}</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Form.Group className="d-flex flex-column">
                            <Row className="align-items-center">
                              <Col xs="auto">
                                <Form.Label>Email:</Form.Label>
                              </Col>
                              <Col className="mt-2">
                                <p>{details?.email}</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Form.Group className="d-flex flex-column">
                            <Row className="align-items-center">
                              <Col xs="auto">
                                <Form.Label>Contact No.:</Form.Label>
                              </Col>
                              <Col className="mt-2">
                                <p>{details?.contact_no}</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="main-form-section mt-3">
                          <Form.Group className="d-flex flex-column">
                            <Row className="align-items-center">
                              <Col xs="auto">
                                <Form.Label>Type:</Form.Label>
                              </Col>
                              <Col className="mt-2">
                                <p>{details?.type}</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>

            </Card.Body>
          </Card>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Account Details</Card.Title>
              <hr />
              <Container>
                <div className="cattable_div">
                  <div className="cattable_main">
                    <Row className="d-flex flex-row">
                      <Col md={5}>
                        <div className="main-form-section mt-3">
                          <Form.Group className="d-flex flex-column">
                            <Row className="align-items-center">
                              <Col xs="auto">
                                <Form.Label>Account Created At:</Form.Label>
                              </Col>
                              <Col className="mt-2">
                                <p>{new Date(details?.createdAt).toLocaleString()}</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Form.Group className="d-flex flex-column">
                            <Row className="align-items-center">
                              <Col xs="auto">
                                <Form.Label>Last Login:</Form.Label>
                              </Col>
                              <Col className="mt-2">
                                <p>{details?.logs?.length > 0 ? new Date(details?.logs[0]?.createdAt).toLocaleString() : ""}</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Form.Group className="d-flex flex-column">
                            <Row className="align-items-center">
                              <Col xs="auto">
                                <Form.Label>Status:</Form.Label>
                              </Col>
                              <Col className="mt-2">
                                <p>{details?.status === 1 ? "Active" : "Inactive"}</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </Col>

                    </Row>
                  </div>
                </div>
              </Container>
            </Card.Body>
          </Card>

          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Purchase History</Card.Title>
              <hr />
              <Container>
                <div className="cattable_div">
                  <div className="cattable_main">
                    <Table responsive className="tablees text-center">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Invoice No</th>
                          <th>Products</th>
                          <th>type</th>
                          <th>Payment Mode</th>
                          <th>Delivery date</th>
                          <th>Total</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {history?.data?.data?.map((item, index) => {
                          const paginatedIndex =
                            (onPageChange - 1) * perPage + index + 1;
                          return (
                            <tr key={index}>
                              <td>{paginatedIndex}</td>
                              <td className="bottomtd commmontext">
                                {item?.invoice_number}
                              </td>
                              <td className="bottomtd commmontext">
                                {item?.orders_details?.map((detail, index) =>
                                  <p>{detail?.product?.name}({detail?.quantity})</p>
                                )}
                              </td>
                              <td className="bottomtd commmontext">
                                {item?.orders_details?.map((detail, index) =>
                                  <p>{detail?.type}</p>
                                )}
                              </td>
                              <td className="bottomtd commmontext">
                                {item?.payment_mode}
                              </td>
                              <td className="bottomtd commmontext">
                                {new Date(item?.delivery_date).toLocaleDateString()}
                              </td>
                              <td className="bottomtd commmontext">
                                {item?.total}
                              </td>
                              <td className="bottomtd commmontext">
                                {item?.order_status?.name}
                              </td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </Table>
                    {history && history?.data?.data?.length > 0 ?
                      <Pagination_Holder
                        onPageChange={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                      : <p className="no-datashow">Sorry, No Data Found</p>}
                  </div>
                </div>

              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditOffCanvance;
