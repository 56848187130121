import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/masters/package-dimension/${id}`);
    reset(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await postData(`/masters/package-dimension/${id}`, data);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Package Dimensions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Length (cm)</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="length"
                        placeholder="Length"
                        className={classNames("", {
                          "is-invalid": errors?.length,
                        })}
                        {...register("length", {
                          required: "Length is required",
                        })}
                        onKeyDown={(e) => {
                          // Allow digits (0-9), backspace, and arrow keys (left, right)
                          if (
                            !/[0-9]/.test(e.key) &&  // not a digit
                            e.key !== "Backspace" &&  // not backspace
                            e.key !== "ArrowLeft" &&  // not left arrow
                            e.key !== "ArrowRight"    // not right arrow
                          ) {
                            e.preventDefault();  // Prevent input if not allowed
                          }
                        }}
                      />
                    </InputGroup>
                    {errors.length && (
                      <span className="text-danger">{errors.length.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={4}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Width (cm)</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="width"
                        placeholder="Width"
                        className={classNames("", {
                          "is-invalid": errors?.width,
                        })}
                        {...register("width", {
                          required: "Width is required",
                        })}
                        onKeyDown={(e) => {
                          // Allow digits (0-9), backspace, and arrow keys (left, right)
                          if (
                            !/[0-9]/.test(e.key) &&  // not a digit
                            e.key !== "Backspace" &&  // not backspace
                            e.key !== "ArrowLeft" &&  // not left arrow
                            e.key !== "ArrowRight"    // not right arrow
                          ) {
                            e.preventDefault();  // Prevent input if not allowed
                          }
                        }}
                      />
                    </InputGroup>
                    {errors.width && (
                      <span className="text-danger">{errors.width.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={4}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Height (cm)</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="height"
                        placeholder="Height"
                        className={classNames("", {
                          "is-invalid": errors?.height,
                        })}
                        {...register("height", {
                          required: "Height is required",
                        })}
                        onKeyDown={(e) => {
                          // Allow digits (0-9), backspace, and arrow keys (left, right)
                          if (
                            !/[0-9]/.test(e.key) &&  // not a digit
                            e.key !== "Backspace" &&  // not backspace
                            e.key !== "ArrowLeft" &&  // not left arrow
                            e.key !== "ArrowRight"    // not right arrow
                          ) {
                            e.preventDefault();  // Prevent input if not allowed
                          }
                        }}
                      />
                    </InputGroup>
                    {errors.height && (
                      <span className="text-danger">{errors.height.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
