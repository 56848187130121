import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import SettingContent from "../../Masters/SettingContent/SettingContent";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import { IDS, Select2Data } from "../../../utils/common";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { ChildCategory } from "../../../utils/apis/master/Master";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    htmlToReactParser,
    isAllow,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);

  const [products, setProducts] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [product_id, setSetProductId] = useState({});


  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);

  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [childSubCategory, setChildSubCategory] = useState([]);

  const formatDateInIST = (date) => {
    if (!date) return null;
    return moment(date).tz('Asia/Kolkata').format('YYYY-MM-DD'); // 'YYYY-MM-DD' format
  };

  const getDataAll = async () => {
    const response = await getData(
      `/salesreport/transactions?page=${currentPage || 1}&per_page=${perPage || 5}&term=${search || ""}&searchCategory=${category?.value || ""}` +
      `&searchSubCategory=${subCategory?.value || ""}&searchChildSubCategory=${childSubCategory?.value || ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));


  };

  useEffect(() => {
    getDataAll();
  }, [perPage, reset, show, show1, category, subCategory, childSubCategory]);

  const getAllProducts = async () => {
    const response = await getData("/all-products");
    // setProducts(Select2Data(response?.data, "product_id"));
    setProducts(await Select2Data(response?.data, "product_id"));
  }

  useEffect(() => {
    getAllProducts();
  }, [])

  console.log("startDate", startDate);



  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };


  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const getMinEndDate = () => {
    if (!startDate) return null; // If startDate is not set, return null
    const date = new Date(startDate); // Convert startDate to a Date object
    date.setDate(date.getDate() + 1); // Add one day
    return date; // Return the updated date object
  };


  const GetAllCategory = async () => {
    const response = await getData("/allcategories");
    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);
    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "subCategories"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildsubcategories/${id}`);
    if (response?.success) {
      setChildSubCategories(
        await Select2Data(response?.data, "childSubCategories")
      );
    }
  };

  useEffect(() => {
    GetAllCategory();
  }, []);

  return (
    <>
      <div className="main-advancedashboard">
        <Header />
        <section className="AdvanceDashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio mt-3">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="d-flex">
                        {isAllow.includes(IDS.Sale.Add) ? (
                          <div className="add me-3">
                            {data?.data?.data?.length >= 1 ? (
                              <div>{/* Your content here */}</div>
                            ) : (
                              <Link
                                // to="/product/salesreport/transactions/add"
                                type="button"
                                className="btn btn-add pe-3"
                              >
                                <div onClick={() => handleShow()}>
                                  <img
                                    src={plus}
                                    className="plus me-2 ms-0"
                                    alt=""
                                  />
                                  Add Sale
                                </div>
                              </Link>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="add me-3">
                          <div className="dropdown">
                            <button
                              className="btn btn-columns dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={colunms}
                                className="columns me-2 "
                                alt=""
                              />
                              Column Selection
                              <img src={top} className="top ms-1" alt="" />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col1")}
                                  href="#"
                                >
                                  Sr. No.
                                  {visible?.col1 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col2")}
                                  href="#"
                                >
                                  Transaction Id
                                  {visible?.col2 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col3")}
                                  href="#"
                                >
                                  Payment Date
                                  {visible?.col3 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col4")}
                                  href="#"
                                >
                                  Payment Method
                                  {visible?.col4 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col5")}
                                  href="#"
                                >
                                  Amount
                                  {visible?.col5 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col6")}
                                  href="#"
                                >
                                  Customer info
                                  {visible?.col6 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col7")}
                                  href="#"
                                >
                                  Order
                                  {visible?.col7 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>


                            </ul>
                          </div>
                        </div>

                      </div>
                      <div className="border-line mt-3"></div>
                      <div className="row mt-4">
                        <div className="  col-lg-4 col-md-12 col-12">
                          <div className="d-flex">
                            <div className="show me-2">
                              <p className="show mt-1">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries">
                              <p className="show mt-1">entries</p>
                            </div>
                          </div>
                        </div>
                        <div className="  col-lg-8  col-md-12 col-12">
                          <div className="d-flex justify-content-end">
                            <div className="sowing me-2">
                              <p className="show mt-2">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                            {/* <div className="num me-2" >
                            <Select
                              placeholder="Select Category"
                              value={category}
                              onChange={(selectedOption) => {
                                setCategory(selectedOption);
                                GetAllSubCategory(selectedOption?.value);
                                setSubCategory("");
                                setChildSubCategory("");
                              }}
                              options={categories}
                            />
                          </div>

                          <div className="num me-2">
                            <Select
                              placeholder="Select Sub Category"
                              value={subCategory}
                              onChange={(selectedOption) => {
                                setSubCategory(selectedOption);
                                GetAllChildSubCategory(selectedOption?.value);
                                setChildSubCategory("");
                              }}
                              options={subCategories}
                            />
                          </div>

                          <div className="num me-2">
                            <Select
                              placeholder="Select Child Category"
                              value={ChildCategory}
                              onChange={(selectedOption) => {
                                childSubCategories(selectedOption);
                              }}
                              options={childSubCategories}
                            />
                          </div> */}

                            {/* <div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}

                            {/* <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setCategory("");
                                setSubCategory("");
                                setChildSubCategory("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && <th className="sr" style={{ width: "20px" }}>Sr. No.</th>}
                              {visible.col2 && <th className="tax-name" style={{ width: "100px" }}>Transaction Id</th>}
                              {visible.col3 && <th className="tax-name" style={{ width: "100px" }}>Payment Date</th>}
                              {visible.col4 && (
                                <th className="tax-name" style={{ width: "100px" }}>Payment Method</th>
                              )}
                              {visible.col5 && (
                                <th className="tax-name" style={{ width: "100px" }}>Amount</th>
                              )}
                              {visible.col6 && (
                                <th className="tax-name" style={{ width: "100px" }}>Customer info</th>
                              )}
                              {visible.col7 && (
                                <th className="tax-name" style={{ width: "100px" }}>Order</th>
                              )}


                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}</td>}
                                  {visible.col2 && <td>{d?.payment_id}</td>}
                                  {visible.col3 && <td>{new Date(d?.createdAt).toLocaleString()}</td>}
                                  {visible.col4 && (
                                    <td> {d?.order?.payment_mode}</td>
                                  )}
                                  {visible.col5 && (
                                    <td> {d?.order?.total}</td>
                                  )}
                                  {visible.col6 && (
                                    <td> {d?.order?.user?.first_name}  {d?.order?.user?.last_name}</td>
                                  )}
                                  {visible.col7 && (
                                    <td> <Link to={`/order-managements/single-order/${d?.order_id}`}>View Order</Link></td>
                                  )}


                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {
        show ? (
          <AddOffCanvance
            handleClose={handleClose}
            setShow={setShowAdd}
            show={show}
          />
        ) : (
          ""
        )
      }

      {
        show1 ? (
          <EditOffCanvance
            handleClose={handleClose1}
            setShow={setShowEdit}
            show={show1}
          />
        ) : (
          ""
        )
      }

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
