// import React, { useContext, useEffect, useRef, useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
// import "./AdvanceDashboard.css";
// import Header from "../../Header/Header";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import "react-circular-progressbar/dist/styles.css";
// import "swiper/swiper-bundle.css";
// import CanvasJSReact from "@canvasjs/react-charts";
// import { Context } from "../../../utils/context";
// import Card from "react-bootstrap/Card";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Accordion from "react-bootstrap/Accordion";
// import {
//   faCartShopping,
//   faBoxOpen,
//   faUsers,
//   faStore,
// } from "@fortawesome/free-solid-svg-icons";
// import { Chart } from "react-google-charts";
// import DataTable from "datatables.net";
// import Button from "react-bootstrap/Button";
// import { Link, useLocation } from "react-router-dom";
// import { Table } from "react-bootstrap";
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// library.add(fas);

// const AdvanceDashboard = () => {

//   const { getData, usertype } = useContext(Context);

//   const [result, setResult] = useState({});

//   const getDataAll = async () => {
//     const result = await getData("/home/dashboard/all-dashboard");
//     setResult(result.data);
//   };

//   useEffect(() => {
//     getDataAll();
//   }, []);

//   const cardsData = [
//     {
//       number: result?.total_orders,
//       types: "Orders",
//       icon: "fa-cart-shopping",
//       className: "#63bff5",  // Static color for orders
//       api: "/order-managements/orders",
//     },
//     {
//       number: result?.total_products,
//       types: "Products",
//       icon: "fa-solid fa-boxes-stacked",
//       className: "#ff5733",  // Different color for products
//       api: "/product",
//     },
//     {
//       number: result?.total_b2b_customers,
//       types: "B2B Customers",
//       icon: "fa-solid fa-users",
//       className: "#4caf50",  // Green color for B2B customers
//       api: "/employee/customer-details",
//     },
//     {
//       number: result?.total_b2c_customers,
//       types: "B2C Customers",
//       icon: "fa-solid fa-users",
//       className: "#ff9800",  // Orange color for B2C customers
//       api: "/employee/customer-details",
//     },
//     {
//       number: "₹ " + result?.total_amount,
//       types: "Total Collection",
//       icon: "fa-solid fa-indian-rupee-sign",
//       className: "#9c27b0",  // Purple color for total collection
//     },
//   ];

//   const options = {
//     data: [
//       {
//         color: "#4285f4",
//         width: 100,
//         dataPoints: [
//           { label: "29-March", y: 10 },
//           { label: "28-March", y: 15 },
//           { label: "20-March", y: 2 },
//           { label: "18-March", y: 30 },
//           { label: "13-March", y: 18 },
//           { label: "27-March", y: 10 },
//           { label: "30-March", y: 20 },
//         ],
//       },
//     ],
//   };

//   // pie chart
//   const dataOne = [
//     ["Task", "Hours per Day"],
//     ["Work", 11],
//     ["Eat", 2],
//     ["Commute", 2],
//     ["Watch TV", 2],
//     ["Sleep", 7],
//   ];

//   const optiontwo = {
//     title: "Product Category Count",
//     is3D: true,
//   };

//   // close button
//   const [showBox, setShowBox] = useState(true);

//   const handleClose = () => {
//     setShowBox(false);
//   };

//   useEffect(() => {
//     const table = new DataTable("#example");
//     return () => {
//       table.destroy();
//     };
//   }, []);

//   useEffect(() => {
//     const table = new DataTable("#exampleContent");
//     return () => {
//       table.destroy();
//     };
//   }, []);

//   const colors = [
//     "#63bff5",  // Light Blue
//     "#f39c12",  // Orange
//     "#dd4b39",  // Red
//     "#00a65a",  // Green
//     "#9b59b6",  // Lavender
//     "#8e44ad",  // Purple
//     "#3498db",  // Sky Blue
//     "#1abc9c",  // Turquoise
//     "#e74c3c",  // Bright Red
//     "#16a085",  // Teal
//     "#f1c40f",  // Yellow
//     "#2ecc71",  // Emerald Green
//     "#e67e22",  // Carrot Orange
//     "#f39c12",  // Sunflower
//     "#34495e",  // Dark Blue (Almost Grey)
//     "#c0392b",  // Strong Red
//     "#2980b9",  // Blue
//     "#1d1d1d",  // Dark Grey
//     "#27ae60",  // Forest Green
//     "#d35400",  // Pumpkin Orange
//     "#f1c40f",  // Bright Yellow
//     "#2c3e50",  // Midnight Blue
//     "#ecf0f1",  // Light Grey
//   ];

//   return (
//     <div className="main-advancedashboard">
//       <section className="AdvanceDashboard">
//         <div className="AdvanceDashboardTabs">
//           <Header title={"Dashboard"} link={"/masters/social_links"} />
//           <div className=""></div>
//         </div>
//       </section>
//       <div className="Main-Section-Advanced-Dashboard">
//         <h1 className="title">Home</h1>
//         <Row>
//           {cardsData.map((item, index) =>
//             usertype === "Admin" ? (
//               <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
//                 <Card
//                   className="small-box mt-3"
//                   style={{ backgroundColor: colors[index % colors.length] }}
//                 >
//                   <div className={item.className}>
//                     <div className="content">
//                       <div class="inner">
//                         <h3>{item.number}</h3>
//                         <p>{item.types}</p>
//                       </div>
//                       <div class="icon">
//                         <FontAwesomeIcon icon={item.icon} />
//                       </div>
//                     </div>
//                     {index !== 4 &&
//                       <Link to={item?.api}>
//                         <p className="mb-0 small-box-footer">
//                           More info{" "}
//                           <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
//                         </p>
//                       </Link>
//                     }
//                   </div>
//                 </Card>
//               </Col>
//             ) : (
//               index < 2 && (
//                 <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
//                   <Card
//                     className="small-box"
//                     style={{ backgroundColor: colors[index % colors.length] }}
//                   >
//                     <div className={item.className}>
//                       <div className="content">
//                         <div class="inner">
//                           <h3>{item.number}</h3>
//                           <p>{item.types}</p>
//                         </div>
//                         <div class="icon">
//                           <FontAwesomeIcon icon={item.icon} />
//                         </div>
//                       </div>

//                       <Link to={item?.api}>
//                         <p className="mb-0 small-box-footer">
//                           More info{" "}
//                           <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
//                         </p>
//                       </Link>
//                     </div>
//                   </Card>
//                 </Col>
//               )
//             )
//           )}
//         </Row>
//         {/* <Row>
//                     <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
//                         <div className="red-cardddd mt-3">
//                             <p className="sold-txtttt"> 3 Product(s) sold out!</p>
//                             <Link>
//                                 <p className="small-box-footer">
//                                     More info <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
//                                 </p>
//                             </Link>
//                         </div>
//                     </Col>
//                     <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
//                         <div className="blue-cardddd mt-3">
//                             <p className="sold-txtttt">1 Product(s) in low stock! (Low stock limt 10)</p>
//                             <Link>
//                                 <p className="small-box-footer">
//                                     More info <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
//                                 </p>
//                             </Link>
//                         </div>
//                     </Col>
//                 </Row> */}
//       </div>

//       {/* <div className="Map-Section">
//                 <Row>
//                     <Col xxl={6}>
//                         <div className="card cardbox box-success">
//                             <div className="card-body">
//                                 <p className="text-one">Weekly sales</p>
//                                 <p className="text">Total Sale in last week</p>
//                                 <div className="Bar-Chart"><CanvasJSChart options={options} containerProps={{ width: '100%', height: '300px' }} /></div>
//                             </div>
//                         </div>
//                     </Col>
//                     <Col xxl={6}>
//                         <div className="box box-success-two">
//                             <Chart
//                                 chartType="PieChart"
//                                 data={dataOne}
//                                 options={optiontwo}
//                                 width={"100%"}
//                                 height={"400px"}
//                             />
//                         </div>
//                     </Col>
//                 </Row>

//                 <Row className="">
//                     <Col xxl={6}>
//                         {showBox && (
//                             <div className="box-two box-warning">
//                                 <Accordion defaultActiveKey="0">
//                                     <Accordion.Item eventKey="0">
//                                         <Accordion.Header>
//                                             <h3 className="box-title">
//                                                 Top Sellers <small>( Month: Mar)</small>
//                                             </h3>
//                                         </Accordion.Header>
//                                         <Accordion.Body>
//                                             <Table responsive id="example" className="display" style={{ width: '100%' }}>
//                                                 <thead>
//                                                     <tr>
//                                                         <th>Name</th>
//                                                         <th>Position</th>
//                                                         <th>Office</th>
//                                                         <th>Age</th>
//                                                         <th>Start date</th>
//                                                         <th>Salary</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {datanew.map((item, index) => (
//                                                         <tr key={index}>
//                                                             <td>{item.Name}</td>
//                                                             <td>{item.Position}</td>
//                                                             <td>{item.Office}</td>
//                                                             <td>{item.Age}</td>
//                                                             <td>{item.StartDate}</td>
//                                                             <td>{item.Salary}</td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </Table>
//                                         </Accordion.Body>
//                                     </Accordion.Item>
//                                 </Accordion>
//                             </div>
//                         )}
//                     </Col>
//                     <Col xxl={6}>
//                         {showBox && (
//                             <div className="box-two box-warning box-categoris">
//                                 <Accordion defaultActiveKey="0">
//                                     <Accordion.Item eventKey="0">
//                                         <Accordion.Header>
//                                             <h3 className="box-title">
//                                                 Top Sellers <small>( Month: Mar)</small>
//                                             </h3>

//                                         </Accordion.Header>
//                                         <Accordion.Body>
//                                             <Table responsive id="exampleContent" className="display" style={{ width: '100%' }}>
//                                                 <thead>
//                                                     <tr>
//                                                         <th>Name</th>
//                                                         <th>Position</th>
//                                                         <th>Office</th>
//                                                         <th>Age</th>
//                                                         <th>Start date</th>
//                                                         <th>Salary</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {datacontent.map((item, index) => (
//                                                         <tr key={index}>
//                                                             <td>{item.Name}</td>
//                                                             <td>{item.Position}</td>
//                                                             <td>{item.Office}</td>
//                                                             <td>{item.Age}</td>
//                                                             <td>{item.StartDate}</td>
//                                                             <td>{item.Salary}</td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </Table>
//                                         </Accordion.Body>
//                                     </Accordion.Item>
//                                 </Accordion>
//                             </div>
//                         )}
//                     </Col>
//                 </Row>
//             </div> */}
//     </div>
//   );
// };

// export default AdvanceDashboard;

import React, { useContext, useEffect, useRef, useState } from "react";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { Link, useNavigate } from "react-router-dom";
import Dashboard_LineChart from "./Dashboard_LineChart/Dashboard_LineChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendDown, faCartFlatbed, faExclamationCircle, faGlobe, faIndustry, faLightbulb, faMessage, faTape, faUser, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import Rating from "@material-ui/lab/Rating";
import { Context } from "../../../utils/context";
import { faJediOrder } from "@fortawesome/free-brands-svg-icons";

function AdvanceDashboard() {
  const [value, setValue] = useState(2);
  const navigate = useNavigate();
  const { getData, usertype } = useContext(Context);

  const [result, setResult] = useState({});
  const [pieData, setPieData] = useState();
  const [avgRating, setAvgRating] = useState(null);

  const getDataAll = async () => {
    const result = await getData("/home/dashboard/all-dashboard");
    setResult(result.data);
    setAvgRating(parseFloat(result.data?.avgRating?.avg_rating).toFixed(1) || 0)
  };

  const getPieData = async () => {
    const result = await getData("/home/dashboard/piedata");
    setPieData(result.data);
  };

  console.log("avgRating", avgRating);


  useEffect(() => {
    getDataAll();
    getPieData();
  }, []);

  return (
    <div>
      <div className="main-advancedashboard">
        <section className="AdvanceDashboard">
          <div className="AdvanceDashboardTabs">
            <Header title={"Dashboard"} link={"/masters/social_links"} />
            <div className=""></div>
          </div>
        </section>
        <div className="Main-Section-Advanced-Dashboard">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-xl-3 col-lg-6">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>Sales</h6>
                        </div>
                        <div className="card-number-holder">
                          <h3>{pieData?.total_sales}</h3>
                          <p>This week</p>
                        </div>

                        <div className="card-chart-holder">
                          <Dashboard_LineChart pieData={pieData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12" onClick={() => navigate("/order-managements/orders")}>
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>Today's Orders</h6>
                        </div>
                        <div className="card-number-holder">

                          <h3>
                            <FontAwesomeIcon className="me-3" icon={faCartFlatbed} />
                            {result?.total_orders}</h3>
                          <p>Today Count</p>
                        </div>
                        <div className="card-table-holder">
                          <Table className="mb-0">
                            <tbody>
                              <tr>
                                <td>Unshipped</td>
                                <td className="num">{result?.unshipped_orders}</td>
                              </tr>
                              <tr>
                                <td>Shipped</td>
                                <td className="num">{result?.shipped_orders}</td>
                              </tr>
                              <tr>
                                <td>Scheduled</td>
                                <td className="num">{result?.scheduled_orders}</td>
                              </tr>
                              <tr>
                                <td>Cancelled</td>
                                <td className="num">{result?.cancelled_orders}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12" onClick={() => navigate("/product/ratings/:id")}>
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>Buyer Messages</h6>
                        </div>
                        <div className="card-number-holder">

                          <h3>
                            <FontAwesomeIcon className="me-3" icon={faMessage} />
                            {result?.reviewCount}</h3>
                          <p>Total Messages </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12">
                      <div className="bucket-card-holder" onClick={() => navigate("/product/ratings/:id")}>
                        <div className="card-heading-holder">
                          <h6>Feedback</h6>
                        </div>
                        <div className="card-number-holder card-rating-holder">
                          <h3 className="align-items-center">
                            <div className="start-rating-holder ">
                              <Rating
                                name="simple-controlled"
                                value={avgRating}
                                precision={0.5}
                                disabled
                              />
                            </div>
                            {result?.total_rating}{" "}
                          </h3>
                          <p>This Year</p>
                        </div>

                        <div className="card-chart-holder"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>Featured Offers %</h6>
                        </div>
                        <div className="card-number-holder">
                          <h3>
                            3564{" "}
                            <FontAwesomeIcon
                              className="down-trend-holder text-danger ms-2"
                              icon={faArrowTrendDown}
                            />
                          </h3>
                          <p>Two days ago</p>
                        </div>

                        <div className="card-chart-holder"></div>
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12">
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>Total</h6>
                        </div>
                        <div className="card-number-holder">

                          <h3>
                            <FontAwesomeIcon className="me-3" icon={faWallet} />
                            {result?.total_amount}</h3>
                          <p>Today's Payment</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6">
                  <div className="row" onClick={() => navigate("/employee/customer-details")}>
                    <div className="col-xl-12 col-lg-12">
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>BTB Customers</h6>
                        </div>
                        <div className="card-number-holder">

                          <h3> <FontAwesomeIcon className="me-3" icon={faIndustry} />
                            {result?.total_b2b_customers}</h3>
                          <p>Total BTB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6">
                  <div className="row" onClick={() => navigate("/employee/customer-details")}>
                    <div className="col-xl-12 col-lg-12">
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>BTC Customers</h6>
                        </div>
                        <div className="card-number-holder">

                          <h3>
                            <FontAwesomeIcon className="me-3" icon={faUser} />
                            {result?.total_b2c_customers}</h3>
                          <p>Total BTC</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6">
                  <div className="row" onClick={() => navigate("/product")}>
                    <div className="col-xl-12 col-lg-12">
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>Products</h6>
                        </div>
                        <div className="card-number-holder">

                          <h3>
                            <FontAwesomeIcon className="me-3" icon={faTape} />
                            {result?.total_products}</h3>
                          <p>Tota Products</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6">
                  <div className="row" onClick={() => navigate("/reports/inventory")}>
                    <div className="col-xl-12 col-lg-12">
                      <div className="bucket-card-holder">
                        <div className="card-heading-holder">
                          <h6>Stock</h6>
                        </div>
                        <div className="card-number-holder">
                          {result?.stock_alerts > 0 ?
                            <>
                              < h3 className="d-flex align-items-center">
                                🚨 {result?.stock_alerts}
                              </h3>
                              <p>Stocks Getting Low</p>
                            </>
                            : <p>Every thing is fine</p>}
                        </div>

                        <div className="card-chart-holder"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="communication-container">
                <div className="communication-heading">
                  <h4>Communications</h4>
                </div>
                <div className="forum-cards-holder">
                  <div className="seller-forums">
                    <h6> 🗨️ Ratings & Reviews</h6>
                    {result?.reviews && result?.reviews?.map((item) =>
                      <div className="forum-item">
                        <h6>{new Date(item?.createdAt).toDateString()}</h6>
                        <p>
                          <p>
                            <Rating
                              defaultValue={parseInt(item?.ratings)}
                              disabled
                            // fullSymbol="★"
                            // emptySymbol="☆"
                            />
                          </p>
                          {item?.description?.length > 120 ? item.description.slice(0, 120) + '....' : item.description}

                        </p>
                        <Link to={`/product/ratings/${item?.id}`}>
                          <span className="read-more">Read more &gt;</span>
                        </Link>
                      </div>
                    )}
                    {/* <div className="forum-item">
                      <h6>18-NOV-2024</h6>
                      <p>
                        As Sellers We all are Tired Of Selling Under These
                        Frustrated...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>20-NOV-2024</h6>
                      <p>FAKE BUYER</p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div> */}
                  </div>
                  <div className="seller-forums">
                    <h6> 📞 Contact</h6>
                    {result?.contact_us && result?.contact_us?.map((item) =>
                      <div className="forum-item">
                        <h6>{new Date(item?.createdAt).toDateString()}</h6>
                        <p>
                          {item?.name}
                        </p>
                        <p>
                          {item?.subject} :-
                          {item?.message?.length > 120 ? item.message.slice(0, 120) + '....' : item.message}
                        </p>
                        <Link to={`/employee/contact-us/${item?.id}`}>
                          <span className="read-more">Read more &gt;</span>
                        </Link>
                      </div>
                    )}
                    {/* <div className="forum-item">
                      <h6>18-NOV-2024</h6>
                      <p>
                        As Sellers We all are Tired Of Selling Under These
                        Frustrated...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>20-NOV-2024</h6>
                      <p>FAKE BUYER</p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div> */}
                  </div>
                </div>

                {/* <div className="seller-forums">
                    <div className="forum-item">
                      <h6>19-NOV-2024</h6>
                      <p>
                        🚨 Get Answers About the NEW Manage All Inventory
                        Experience...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>18-NOV-2024</h6>
                      <p>
                        As Sellers We all are Tired Of Selling Under These
                        Frustrated...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>20-NOV-2024</h6>
                      <p>FAKE BUYER</p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                  </div>

                  <div className="seller-forums">
                    <div className="forum-item">
                      <h6>19-NOV-2024</h6>
                      <p>
                        🚨 Get Answers About the NEW Manage All Inventory
                        Experience...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>18-NOV-2024</h6>
                      <p>
                        As Sellers We all are Tired Of Selling Under These
                        Frustrated...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>20-NOV-2024</h6>
                      <p>FAKE BUYER</p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                  </div>

                  <div className="seller-forums">
                    <div className="forum-item">
                      <h6>19-NOV-2024</h6>
                      <p>
                        🚨 Get Answers About the NEW Manage All Inventory
                        Experience...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>18-NOV-2024</h6>
                      <p>
                        As Sellers We all are Tired Of Selling Under These
                        Frustrated...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>20-NOV-2024</h6>
                      <p>FAKE BUYER</p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                  </div>

                  <div className="seller-forums">
                    <div className="forum-item">
                      <h6>19-NOV-2024</h6>
                      <p>
                        🚨 Get Answers About the NEW Manage All Inventory
                        Experience...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>18-NOV-2024</h6>
                      <p>
                        As Sellers We all are Tired Of Selling Under These
                        Frustrated...
                      </p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                    <div className="forum-item">
                      <h6>20-NOV-2024</h6>
                      <p>FAKE BUYER</p>
                      <Link to={"/"}>
                        <span className="read-more">Read more &gt;</span>
                      </Link>
                    </div>
                  </div> */}

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvanceDashboard;
