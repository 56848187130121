import React, { useContext } from "react";
import { useState, useEffect } from "react";
import Select from "react-select";
import { Table, Button, Form } from "react-bootstrap";
import { Context } from "../../../../utils/context";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

function Sent_Table({
  data, setSearchOrderSatatus, handleCancelOrer, statusCount, getDataAll, resultsPerPageOptions, customerType,
  perPage, setperPage, setCustomerType, setSortOrder, setReset, downloadInvoice, hideFilter, setHideFilter
}) {
  const {
    IMG_URL
  } = useContext(Context);
  const shipByDateOptions = [
    { value: "ascending", label: "Ship-by date (ascending)" },
    { value: "descending", label: "Ship-by date (descending)" },
  ];
  const navigate = useNavigate();

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "20px",
      borderColor: "#ddd",
      boxShadow: "none",
      fontSize: "15px",
      "&:hover": {
        borderColor: "#aaa",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#000",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff",
      color: "#000",
      fontSize: "15px",
      "&:hover": {
        backgroundColor: "#eee",
      },
    }),
  };

  const [activeTab, setActiveTab] = useState("waitingForPickup"); // Set 'shipped' as the default active tab

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const calculateTimeAgo = (date) => {
    const createdAt = new Date(date);
    const now = new Date();
    const diffInMilliseconds = now - createdAt;

    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (diffInDays >= 1) {
      return `${diffInDays} Day${diffInDays > 1 ? 's' : ''} Ago`;
    } else if (diffInHours >= 1) {
      return `${diffInHours} Hour${diffInHours > 1 ? 's' : ''} Ago`;
    } else {
      return `${diffInMinutes} Min Ago`;
    }
  };

  const formatTimeInIST = (date) => {
    const createdAt = new Date(date);
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    const istTime = createdAt.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', ...options });
    // setFormattedTime(`${istTime} IST`);
    return istTime
  };

  return (
    <section className="pending-table_holder">
      {/* back button start */}
      <div className="back_btn_holder" >
        <FontAwesomeIcon className="back-btn" icon={faAngleLeft} onClick={() => navigate("/reports/transactions")} /> Back
      </div>
      {/* back button end */}
      {/* Table Section */}
      <div className="pending-table ">
        <Table striped bordered hover className="order-table" responsive="xl">
          <thead>
            <tr>
              {/* <th>
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                />
              </th> */}
              <th>Order Date</th>
              <th>Order details</th>
              <th>Image</th>
              <th>Product name</th>
              <th>Order type</th>
              <th>Order Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

            {data?.data?.data?.map((item, index) =>
              <tr key={index}>
                {/* <td>
                  <Form.Check // prettier-ignore
                    type={"checkbox"}
                  />
                </td> */}
                <td className="order-date">
                  <div className="order-id">{calculateTimeAgo(item?.createdAt)}</div>
                  <div className="order-info">
                    <span>{new Date(item?.createdAt).toISOString().split('T')[0]}</span>
                  </div>
                  <div className="order-info">
                    <span>{formatTimeInIST(item?.createdAt)} IST</span>
                  </div>
                </td>

                <td className="order-details">
                  <div className="order-id text-purple-holder">
                    {/* 404-8206376-0718713 */}
                    {item?.invoice_number}
                  </div>
                  <div className="order-info">
                    <span>Buyer Name</span>
                  </div>
                  <div className="order-info">
                    <span className="text-highlight">{item?.user?.first_name} {item?.user?.last_name}</span>
                  </div>
                  {/* <div className="order-info">
                    <span>Fulfillment method: {item?.user?.type}</span>
                  </div> */}
                  {/* <div className="order-info">
                    <span>Sales channel: Amazon.in</span>
                  </div> */}

                  {/* <div className="order-info">
                    <span className="text-highlight">✓prime</span>
                  </div> */}

                  <div className="order-info">
                    <span>{item?.payment_mode === 'cod' ? 'Cash On Delivery' : 'Online'}</span>
                  </div>

                  <div className="order-info">
                    <span>{item?.user?.type}</span>
                  </div>
                </td>
                <td>
                  <img
                    src={item?.orders_details ? IMG_URL + item?.orders_details[0]?.product?.image : ""}
                    alt="Product"
                    className="product-image"
                  />
                </td>
                <td className="product-name">
                  {item?.orders_details?.map((pro) =>
                    <>
                      <div className="order-id text-purple-holder ">
                        {pro?.product?.name}
                        <span>{pro?.product?.short_description}</span>
                      </div>

                      <div className="order-info">
                        <span>
                          HSN: <b>{pro?.product?.hsn_code}</b>
                        </span>
                      </div>

                      <div className="order-info">
                        <span>SKU: {pro?.product?.sku_id}</span>
                      </div>

                      <div className="order-info">
                        <span>Quantity: {pro?.quantity}</span>
                      </div>


                    </>
                  )}
                  <div className="order-info">
                    <span> subtotal: {parseFloat(item?.total)}</span>
                  </div>
                </td>
                <td>
                  <div className="order-id">
                    <span> {item?.orders_details ? item?.orders_details[0]?.type : ""}</span>
                  </div>
                  <div className="order-info">
                    <span>Ship by date:  {item?.shipping_detail?.date} , {item?.shipping_detail?.time}IST</span>
                  </div>
                  {/* <div className="order-info">
                    <span>Deliver by date: Mon, 11 Nov, 2024 IST</span>
                  </div> */}
                </td>
                <td>
                  {item?.order_status_id === 4 ?
                    <div>
                      <p className="status-holder shipped">shipped</p>
                    </div>
                    :
                    <div>
                      <p className="status-holder Unshipped">Unshipped</p>
                    </div>
                  }
                </td>
                <td>
                  {item?.order_status_id === 2 &&
                    <div className="my-1">
                      <Link to={`/order-managements/order/schedule-pickup/${item?.id}`}>
                        <Button className="action-btn active">
                          Schedule Pickup
                        </Button>
                      </Link>
                    </div>
                  }

                  <div className="my-1">
                    <Button className="action-btn" onClick={() => downloadInvoice(IMG_URL + item?.shipping_detail?.shipping_slip)}>Print Packing Slip</Button>
                  </div>

                  <div className="my-1">
                    <Button className="action-btn" onClick={() => downloadInvoice(IMG_URL + item?.invoice)}>Print Tax Invoice</Button>
                  </div>

                  {/* <div className="my-1">
                    <Button className="action-btn" onClick={() => handleCancelOrer(item?.id)}>Cancel Order</Button>
                  </div> */}
                </td>
              </tr>
            )}

            {/* <tr>
              <td>
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                />
              </td>
              <td className="order-date">
                <div className="order-id">37 Min Ago</div>
                <div className="order-info">
                  <span>12/12/2024</span>
                </div>
                <div className="order-info">
                  <span>05:00PM IST</span>
                </div>
              </td>
              <td className="order-details">
                <div className="order-id text-purple-holder">
                  404-8206376-0718713
                </div>
                <div className="order-info">
                  <span>Buyer Name</span>
                </div>
                <div className="order-info">
                  <span className="text-highlight"> M</span>
                </div>
                <div className="order-info">
                  <span>Fulfillment method: Seller</span>
                </div>
                <div className="order-info">
                  <span>Sales channel: Amazon.in</span>
                </div>

                <div className="order-info">
                  <span className="text-highlight">✓prime</span>
                </div>

                <div className="order-info">
                  <span>Cash On Delivery</span>
                </div>

                <div className="order-info">
                  <span>Business Customer</span>
                </div>
              </td>
              <td>
                <img
                  src="https://via.placeholder.com/100"
                  alt="Product"
                  className="product-image"
                />
              </td>
              <td className="product-name">
                <div className="order-id text-purple-holder ">
                  CMJ INDUSTRIES Transparent Tape 2 Inch 48mm 50 Meter length 38
                  Micron Pack Of 06 Rolls
                </div>

                <div className="order-info">
                  <span>
                    ASIN: <b>BOCGFSQL1D</b>
                  </span>
                </div>

                <div className="order-info">
                  <span>SKU: CMJ-TRN-02-40-06</span>
                </div>

                <div className="order-info">
                  <span>Quantity: 2</span>
                </div>

                <div className="order-info">
                  <span>Item subtotal: 530.00</span>
                </div>
              </td>
              <td>
                <div className="order-id">
                  <span>Standard</span>
                </div>
                <div className="order-info">
                  <span>Ship by date: Sun, 10 Nov, 2024 IST</span>
                </div>
                <div className="order-info">
                  <span>Deliver by date: Mon, 11 Nov, 2024 IST</span>
                </div>
              </td>
              <td>
                <div>
                  <p className="status-holder Unshipped">Unshipped (2)</p>
                </div>

                <div>
                  <p className="status-holder shipped">shipped (1)</p>
                </div>
              </td>
              <td>
                <div className="my-1">
                  <Link to={"/order-managements/order/schedule-pickup"}>
                    <Button className="action-btn active">
                      Schedule Pickup
                    </Button>
                  </Link>
                </div>

                <div className="my-1">
                  <Button className="action-btn">Print Packing Slip</Button>
                </div>

                <div className="my-1">
                  <Button className="action-btn">Print Tax Invoice</Button>
                </div>

                <div className="my-1">
                  <Button className="action-btn">Cancel Order</Button>
                </div>
              </td>
            </tr> */}
          </tbody>
        </Table>
      </div>
    </section>
  );
}

export default Sent_Table;
